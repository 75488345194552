import { useEffect, useState } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import './EventRSVPView.scss';
import { Button, Checkbox, Form, Input, Row, Space, Typography, Radio, notification, Spin } from "antd"
import { signInHandler } from '../../../Utils/Helper/msalFunctions';
import { UserOutlined } from '@ant-design/icons';
import { getReadableColour, getTextColor } from '../../../Utils/Helper/displayFunctions';
import axios from "axios";
import parse from 'html-react-parser';
import { Event, EventRSVPSessionTime, EventRsvpForm } from '../../../Utils/Types/EventTypes';
import { getToken } from '../../../MSALExtensions';
import { publicClientApplication } from '../../..';
import { AuthScopes } from '../../../AuthConfig';

interface EventVars {
  event: Event;
  displayText: string;
  eventTime: Date;
  colorTheme: string;
  alias: string;
}

const EventRSVPView: React.FC<EventVars> = ({ event, displayText, colorTheme, alias }): JSX.Element => {

  const [rsvpForm] = Form.useForm();
  const { accounts, instance } = useMsal();
  const [description, setDescription] = useState();
  const [sessionTimes, setSessionTimes] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [hasRSVP, setHasRSVP] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // NOTE: this query is separate from the main query due to some quirk with Orchard
  const RSVP_SECTION_P_QUERY = `{
    event(where: {contentItemId: "${event.contentItemId}"}) {
      contentItemId
      rSVPFormRowParagraph {
        html
      }
      rSVPSessionTimesOptions {
        contentItems {
          ... on EventRSVPSessionTime {
            contentItemId
            session
          }
        }
      }
    }
  }`

  useEffect(() => {
    /* PREPOPULATE FROM ACCOUNT */
    (async () => {
      if (accounts.length > 0) {

        rsvpForm.setFieldsValue({ name: accounts[0].idTokenClaims?.name, email: accounts[0].idTokenClaims?.email });
        // check if rsvp'ed
        const accessToken = await getToken(publicClientApplication, AuthScopes.EventApi);
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };

        axios.get(`${process.env.REACT_APP_ACADEMY_EVENT_API}/event/${event.contentItemId}/me`, { headers })
          .then((response) => {
            if (response.status === 200 && response.data === true) {
              setHasRSVP(true)
            }
            else {
              // prepop

            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    })();

    /* LOAD CUSTOM FORM DETAILS  */
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_ORCHARD_API_URL}/api/graphql`,
      headers: {
        'Content-Type': 'application/graphql'
      },
      data: RSVP_SECTION_P_QUERY
    };

    axios(config).then(function (response: any) {
      const result = response.data.data.event[0];
      if (result) {
        if (result.rSVPFormRowParagraph) {
          setDescription(result.rSVPFormRowParagraph.html)
        }

        if (result.rSVPSessionTimesOptions) {
          setSessionTimes(result.rSVPSessionTimesOptions.contentItems)
        }
      }
      else {
        // TODO: no data front end
      }
    })
      .catch(function (error: any) {
        console.log(error);
      });


  }, []);

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
    },
  };

  const onSubmit = async (values: any) => {
    setIsLoading(true);

    const accessToken = await getToken(publicClientApplication, AuthScopes.EventApi);
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const toSubmit = {
        eventId: event.contentItemId,
        eventLink: `${process.env.REACT_APP_DOMAIN_URL}/events/${alias}`,
        guestFullName: values.name || "Guest",
        guestEmail: values.email,
        eventName: event.displayText || "Event",
        eventLocation: `${event.eventVenue.displayName} \n ${event.eventVenue.address1} ${event.eventVenue.address2 ? `\n ${event.eventVenue.address2}` : ''} \n ${event.eventVenue.city} ${event.eventVenue.state ? `\n ${event.eventVenue.state}` : ''} \n ${event.eventVenue.country} \n ${event.eventVenue.postcode}`,
        eventDateTime: event.startDate,
        sessionTime: (values.sessionTime && values.sessionTime !== "") ? values.sessionTime : new Date(event.startDate),
        rsvpReminders: "",
    }

    await axios.post(`${process.env.REACT_APP_ACADEMY_EVENT_API}/Event`, toSubmit, { headers })
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          // TODO: show results
          setHasRSVP(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        api['error']({
          message: 'Error in RSVP',
          description:
            'There is a problem sending out your RSVP. Please try again later.',
        });
      });
  }

  return (
    <>
      <Row>
        <Typography.Paragraph className="event-section-title" style={{
          color: getReadableColour(colorTheme, true) || '#78880A',
        }}>
          <UnauthenticatedTemplate>Sign in to </UnauthenticatedTemplate><span>{hasRSVP ? "You have an RSVP" : "RSVP"}</span> for {displayText}
        </Typography.Paragraph>
      </Row>
      <Spin spinning={isLoading}>
        <Row className="event-rsvp-container">
          {description && <Row><Typography.Paragraph>{parse(description)}</Typography.Paragraph></Row>}
          <UnauthenticatedTemplate>
            <Space className="event-rsvp-locked-view-content" direction='vertical'>
              <Button className="event-rsvp-locked-sign-in" onClick={() => signInHandler(instance)} block style={{
                      backgroundColor: colorTheme || '#BCD036',
                      color: getTextColor(colorTheme) || 'black',
                      borderColor: colorTheme || '#BCD036',
                    }}>Sign In</Button>
            </Space>
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            {!hasRSVP ?
              (<Form form={rsvpForm}
                className="rsvpForm"
                validateMessages={validateMessages}
                onFinish={onSubmit}
                layout="vertical"
              >
                <Form.Item name="name" rules={[{ required: true }]}>
                  <Input className="rsvpForm__input" placeholder="Full name*" />
                </Form.Item>
                <Form.Item name="email" rules={[{ required: true, type: 'email' }]}>
                  <Input className="rsvpForm__input" placeholder="Email" />
                </Form.Item>
                <Form.Item name="tel">
                  <Input className="rsvpForm__input" placeholder="Phone" />
                </Form.Item>
                {
                  sessionTimes.length > 0 && (
                    <Form.Item name="sessionTime" label="Session Time" rules={[{ required: true, message: 'Please indicate the session time you will be attending!' }]}>
                      <Radio.Group><Space direction="vertical">
                        {
                          sessionTimes.map((s: EventRSVPSessionTime) => {
                            const dateoption = new Date(s.session)
                            return <Radio value={dateoption}>
                              {dateoption.toLocaleString('en-GB', {
                                day: '2-digit',
                                year: 'numeric',
                                month: 'long',
                                hour: 'numeric',
                                minute: '2-digit',
                                timeZoneName: 'short'
                              })}
                            </Radio>
                          })
                        }
                      </Space></Radio.Group>
                    </Form.Item>
                  )
                }
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="rsvpForm__submit"
                    style={{
                      backgroundColor: colorTheme || '#BCD036',
                      color: getTextColor(colorTheme) || 'black',
                      borderColor: colorTheme || '#BCD036',
                    }}>
                    Submit
                  </Button>
                </Form.Item>
              </Form>)
              : (<Typography.Paragraph><b>You are registered successfully for this event.</b> Check your email for your reservation confirmation.</Typography.Paragraph>)
            }
          </AuthenticatedTemplate>
        </Row>
      </Spin>
    </>
  )
}

export default EventRSVPView
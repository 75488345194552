import AdminPanel from '../Components/AdminPanel/AdminPanel';
import PageHeader from '../Components/PageHeader/PageHeader';
import { publicClientApplication } from "../";
import { isAdminUser } from "../MSALExtensions";
import { Result, Row } from 'antd';

function AdminPanelPage() {
    const pca = publicClientApplication;
    const isAdmin = isAdminUser(pca);

    if (isAdmin) {return (
        <>
            <PageHeader title="Admin Panel" bannerUrl="./images/test-images/joinluceco.jpg" desc={null}/>
            <AdminPanel />
        </>
    )}
    else {
        return (<Row className="row-padded admin-panel">
            <Result
                status="error"
                title="Access Denied"
                subTitle="Sign in to an administrator account to get access."
            />
        </Row>);
    };
}

export default AdminPanelPage;
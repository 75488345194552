import { useEffect, useState } from "react";
import CourseList from "../Components/CourseList/CourseList";
import PageHeader from "../Components/PageHeader/PageHeader";
import UpcomingCourses from "../Components/UpcomingCourses/UpcomingCourses";

import axios from "axios";

function CoursesPage() {
    const COURSELIST_COPY_QUERY = `{
        pageCopies(first: 1) {
           courseListPageCopy {
            html
            markdown
          }
        }
      }`

      const [courseListPageCopy, setCourseListPageCopy] = useState("");

    useEffect(() => {
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_ORCHARD_API_URL}/api/graphql`,
            headers: { 
              'Content-Type': 'application/graphql'
            },
            data : COURSELIST_COPY_QUERY
        };
          
        axios(config).then(function (response: any) {
            const result = response.data.data?.pageCopies[0]?.courseListPageCopy?.html;
          if (result) {
             setCourseListPageCopy(result);
          }
          else {
              // TODO: no data front end
          }
        });
    });
        
    return (
        <div className="courses-page">
            <PageHeader title="Available Courses" bannerUrl="./images/test-images/zoomi.jpg" desc={courseListPageCopy}/>
        <>
            <CourseList />
            <UpcomingCourses />
        </>
        </div>
    );
}


export default CoursesPage;
import './Navbar.scss';
import { Link } from "react-router-dom";
import { Layout, Menu, Space } from 'antd';
import type { MenuProps } from 'antd';
import { ReactComponent as LucecoAcademySVG } from '../../Utils/Assets/Logos/Luceco-Academy-Logo.svg';
import { FileExcelFilled, MenuOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import { NavBar } from 'antd-mobile';
import UserButton from './UserButton/UserButton';
import { useEffect, useState } from 'react';
import axios from "axios";



const COURSELIST_QUERY = `{
    course {
      alias {
        alias
      }
      tags
      displayText
    }
  }
  `



const Navbar: React.FC = (): JSX.Element => {
    const [coursesList, setCoursesList] = useState()

    const isMobile = useMediaQuery({ maxWidth: 768 });

    const menuItems: MenuProps['items'] = [{
        key: 'home',
        label: 'Home',
    }, {
        key: 'courses',
        label: 'CPD Courses'
    }, {
        key: 'resources',
        label: 'Resources'
    }, {
        key: 'events',
        label: 'Events'
    },
    {
        key: 'about',
        label: 'About'
    },];


    useEffect(() => {
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_ORCHARD_API_URL}/api/graphql`,
            headers: {
                'Content-Type': 'application/graphql'
            },
            data: COURSELIST_QUERY
        };

        axios(config).then(function (response) {
            const result = response.data.data.resource;
            if (result) {
                setCoursesList(result)
            }
            else {
            }
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (<>
        {!isMobile ?
            <Layout.Header className="navbar">
                <Space className="navbar__content">
                    <div className="header-icon">
                        <Link to='/'><LucecoAcademySVG className="navbar__logo" /></Link>
                    </div>
                    <Menu className="navbar__menu" mode="horizontal" >
                        <div>
                            {menuItems.map((menuitem: any) =>
                                <Menu.Item
                                    className="navbar__menu-item"
                                    key={menuitem?.key}>
                                    <Link
                                        className="navbar__link"
                                        to={`/${menuitem?.key}`}>
                                        <span>{menuitem?.label}</span>
                                    </Link>
                                </Menu.Item>
                            )}
                        </div>
                        <div className="navbar_btns">
                            <Menu.Item className="navbar__btn">
                                <Link to="/contact">
                                    <span>Get in touch</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item className="navbar__btn">
                                <UserButton />
                            </Menu.Item>
                        </div>
                    </Menu>
                </Space>
            </Layout.Header> :
            <NavBar left={<Menu className="header-menu-mobile" mode="horizontal" >
                <Menu.SubMenu className="mobile-submenu" key="" title={<MenuOutlined />} theme="dark">
                    {menuItems.map((menuitem: any) =>
                        <Menu.Item key={menuitem?.key}>
                            <Link to={`/${menuitem?.key}`}>
                                <span>{menuitem?.label}</span>
                            </Link>
                        </Menu.Item>
                    )}
                </Menu.SubMenu>
            </Menu>}
                right={<UserButton />}
                back={null} className="Navbar-Mobile">
                <div className="header-icon">
                    <Link to='/'><LucecoAcademySVG /></Link>
                </div>
            </NavBar>
        } </>
    )

};

export default Navbar;
import { ConfigProvider, Layout } from 'antd';
import { Outlet } from "react-router-dom";

import './App.scss';
import LucecoFooter from './Components/LucecoFooter/LucecoFooter';
import TempNavbar from './Components/Navbar/TempNav';

function App() {

  return (
    <ConfigProvider
    theme={{
      token: {
        fontFamily: 'Jost',
      },
    }}
  >
      <Layout className="App">
          <TempNavbar />
          <div className="outlet">
            <Outlet />
           </div>

          <LucecoFooter />
      </Layout>
      </ConfigProvider>
  );
}

export default App;

import { CourseContent, CourseHtmlContent, CourseQuiz, CourseText, CourseVideo } from '../../../Utils/Types/CourseTypes';
import './CourseContentView.scss';
import CourseFormView from './CourseFormView/CourseFormView';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import CourseIframeContentView from './CourseIframeContent/CourseIframeContentView';

interface courseContentViewProps {
    courseContent: CourseContent;
    nextStep: any;
    setCanNext: any;
}

const CourseContentView: React.FC<courseContentViewProps> = ({courseContent, nextStep, setCanNext}): JSX.Element => {
    // separate this into component(s)
    const parseContent = () => {
        let content = <></>

        switch (courseContent.contentType) {
            case "CourseVideo":
                const vid = courseContent as CourseVideo;
                content = <iframe
                    width="853"
                    height="480"
                    src={vid.courseIframeContent?.url}
                    title={vid.displayText}
                />
                break;
            case "CourseQuiz":
                const quiz = courseContent as CourseQuiz;
                content = <CourseFormView courseContent={quiz} nextStep={nextStep} setCanNext={setCanNext}/>
                break;
            case "CourseText":
                const richText = (courseContent as CourseText).textBody.html;
                const fixedText = richText.replace("<img src=\"", `<img src=\"${process.env.REACT_APP_ORCHARD_API_URL}`);
                content = <div className="course-custom-html" style={{}}>{parse(fixedText)}</div>
                break;
            case "CourseHtmlContent":
                const customHtml = (courseContent as CourseHtmlContent).htmlBody.html;
                const sanitizedHtml = DOMPurify.sanitize(customHtml,{ USE_PROFILES: { html: true } });
                content = <div className="course-custom-html" style={{}}>{parse(sanitizedHtml)}</div>
                break;
            case "CourseIframeContent":
            case "CourseCustomIframeContent":
                content = <CourseIframeContentView iframeContent={courseContent} flag={courseContent.contentType} />
                break;
            default:
                break;
        }

        return content;
    }

    return (
        <>
            {parseContent()}
        </>
    );
};

export default CourseContentView;
import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Layout, Menu, Space, Typography, Drawer } from 'antd';
import { ReactComponent as LucecoAcademySVG } from '../../Utils/Assets/Logos/Luceco-Academy-Logo.svg';
import { ReactComponent as LAMenu } from '../../Utils/Assets/icon_menu_.svg';
import { Link, useNavigate } from 'react-router-dom';
import UserButton from './UserButton/UserButton';

interface MenuItem {
    contentItemId: string;
    contentType: string;
    displayText: string;
    courseMetaDescription?: string;
    description?: string;
    resourceFile: {
        urls: string;
    }
    displayImage?: {
        urls: string[];
    };
    alias?: {
        alias: string;
    };
    tags?: string[];
    isItListed?: boolean;
}

const TempNavbar: React.FC = (): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);
    const [openMenu, setOpenMenu] = useState<string | null>(null);
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [activeMobileMenu, setActiveMobileMenu] = useState<string | null>(null);
    const navRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        const MEGAMENU_QUERY = `{
            resource(status: PUBLISHED) {
              displayText
              tags
              contentItemId
              contentType
              resourceFile {
                urls
              }
              displayImage {
                paths
                urls
              }
              description
            }
            course(status: PUBLISHED) {
              alias {
                alias
              }
              displayImage {
                paths
                urls
              }
              tags
              displayText
              contentItemId
              contentType
              courseMetaDescription
            }
            event(status: PUBLISHED) {
              alias {
                alias
              }
              displayText
              isItListed
              contentItemId
              contentType
              metaDescription
            }
        }`;

        axios.post(`${process.env.REACT_APP_ORCHARD_API_URL}/api/graphql`, { query: MEGAMENU_QUERY })
            .then(response => {
                const { course, resource, event } = response.data.data;
                const mergedMenuItems = [
                    ...(course || []),
                    ...(resource || []),
                    ...(event || []),
                ];
                setMenuItems(mergedMenuItems);
            })
            .catch(error => console.error(error))
            .finally(() => setIsLoading(false));
    }, []);

    const handleMenuOpen = (menuName: string) => {
        setOpenMenu(openMenu === menuName ? null : menuName);
    };

    const handleCourseClick = (alias?: string) => {
        if (alias) {
            navigate(`/courses/${alias}`, { replace: true });
            navigate(0);
            toggleDrawer();
        }
    };

    const handleEventClick = (alias?: string) => {
        if (alias) {
            navigate(`/events/${alias}`, { replace: true });
            navigate(0);
            toggleDrawer();
        }
    }

    const handleClose = () => {
        setOpenMenu(null);
    }

    const toggleDrawer = () => {
        setDrawerVisible(!drawerVisible);
    };

    const toggleMobileMenu = (menuName: string) => {
        setActiveMobileMenu(activeMobileMenu === menuName ? null : menuName);
    };

    return (
        <Layout.Header ref={navRef} className="navbar">
            <Space className="navbar__content">
                <div className="header-icon">
                    <Link to="/">
                        <LucecoAcademySVG className="navbar__logo" />
                    </Link>
                </div>
                <Menu className="navbar__menu" mode="horizontal" onMouseLeave={handleClose}>
                    <div className="navbar__menu-items navbar--desktop">
                        <Menu.Item className="navbar__menu-item" >
                            <Link to="/" className="navbar__link">Home</Link>
                        </Menu.Item>
                        <Menu.Item className={`navbar__menu-item navbar__menu-item--parent ${openMenu === 'CPD Courses' ? 'active' : ''}`}>
                            <span className="navbar__link" onMouseEnter={() => handleMenuOpen('CPD Courses')}>CPD Courses</span>
                            {openMenu === 'CPD Courses' && (
                                <div className="navbar__drop-menu">
                                    <div className="navbar__drop-links">
                                        <ul>
                                            {menuItems
                                                .filter(item => item.contentType === 'Course')
                                                .map((item, index) => (
                                                    <li key={item.contentItemId}>
                                                        <span className="navbar__drop-link" onClick={() => handleCourseClick(item.alias?.alias)}>
                                                            {item.displayText}
                                                        </span>
                                                    </li>
                                                ))}
                                            {menuItems
                                                .filter(item => item.contentType === 'Course')
                                                .slice(0, 1).map((item, index) => (
                                                    <li key={index}>
                                                        <Link to={`/${item.contentType.toLowerCase()}s`} className="navbar__drop-link navbar__drop-link--all"><strong>View all {item.contentType}s</strong></Link>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                    <div className="navbar__drop-imgs">
                                        {menuItems
                                            .filter(item => item.contentType === 'Course')
                                            .slice(0, 1).map((item, index) => (
                                                <div key={index}>
                                                    <img alt="example" className="navbar__drop-img" src={`${process.env.REACT_APP_ORCHARD_API_URL}${item.displayImage?.urls[0]}`} />
                                                    <Typography.Paragraph className="navbar__drop-text">{item.courseMetaDescription ? item.courseMetaDescription : ''}</Typography.Paragraph>
                                                    <Button className="navbar__drop-all" onClick={() => navigate(`/${item.contentType.toLowerCase()}s/${item.alias?.alias}`)}>View {item.contentType}</Button>
                                                </div>

                                            ))}
                                    </div>
                                </div>

                            )}
                        </Menu.Item>
                        <Menu.Item className={`navbar__menu-item navbar__menu-item--parent ${openMenu === 'Resources' ? 'active' : ''}`}>
                            <span className="navbar__link" onMouseEnter={() => handleMenuOpen('Resources')}>Resources</span>
                            {openMenu === 'Resources' && (
                                <div className="navbar__drop-menu">
                                    <div className="navbar__drop-links">
                                        <ul>
                                            {menuItems
                                                .filter(item => item.contentType === 'Resource')
                                                .map((item, index) => (
                                                    <li key={index}>
                                                        <Link className="navbar__drop-link" to={`${process.env.REACT_APP_ORCHARD_API_URL}${item.resourceFile.urls[0]}`} target="_blank">
                                                            {item.displayText}
                                                        </Link>
                                                    </li>
                                                ))}
                                            {menuItems
                                                .filter(item => item.contentType === 'Resource')
                                                .slice(0, 1).map((item, index) => (
                                                    <li key={index}>
                                                        <Link to={`/${item.contentType.toLowerCase()}s`} className="navbar__drop-link navbar__drop-link--all"><strong>View all {item.contentType}s</strong></Link>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                    <div className="navbar__drop-imgs">
                                        {menuItems
                                            .filter(item => item.contentType === 'Resource')
                                            .slice(0, 1).map((item, index) => (
                                                <div key={index}>
                                                    <img alt="example" className="navbar__drop-img" src={`${process.env.REACT_APP_ORCHARD_API_URL}${item.displayImage?.urls[0]}`} />
                                                    <Typography.Paragraph className="navbar__drop-text">{item.description ? item.description : ''}</Typography.Paragraph>
                                                    <Button className="navbar__drop-all" onClick={() => window.location.href = `${process.env.REACT_APP_ORCHARD_API_URL}${item.resourceFile.urls[0]}`} target="_blank">View {item.contentType}</Button>
                                                </div>

                                            ))}
                                    </div>
                                </div>
                            )}
                        </Menu.Item>
                        <Menu.Item className={`navbar__menu-item navbar__menu-item--parent ${openMenu === 'Events' ? 'active' : ''}`}>
                            <span className="navbar__link" onMouseEnter={() => handleMenuOpen('Events')}>Events</span>
                            {openMenu === 'Events' && (
                                <div className="navbar__drop-menu">
                                    <div className="navbar__drop-links">
                                        <ul>
                                            {menuItems
                                                .filter(item => item.contentType === 'Event')
                                                .map((item, index) => (item.isItListed &&
                                                    <li key={index}>
                                                        <span className="navbar__drop-link" onClick={() => handleEventClick(item.alias?.alias)}>
                                                            {item.displayText}
                                                        </span>
                                                    </li>
                                                ))}
                                            {menuItems
                                                .filter(item => item.contentType === 'Event')
                                                .slice(0, 1).map((item, index) => (
                                                    <li key={index}>
                                                        <Link to={`/${item.contentType.toLowerCase()}s`} className="navbar__drop-link navbar__drop-link--all"><strong>View all {item.contentType}s</strong></Link>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </Menu.Item>
                        <Menu.Item className="navbar__menu-item">
                            <Link to="/about" className="navbar__link">About</Link>
                        </Menu.Item>
                    </div>
                    <div className="navbar_btns">
                        <Menu.Item className="navbar__btn">
                            <Link to="/contact" className="navbar__btn-yellow navbar--desktop">
                                <span>Get in touch</span>
                            </Link>
                            <Button className="navbar__btn-yellow navbar--mobile" onClick={toggleDrawer}>
                                <LAMenu />
                            </Button>
                        </Menu.Item>
                        <Menu.Item className="navbar__btn">
                            <UserButton />
                        </Menu.Item>
                    </div>
                </Menu>
            </Space>
            <Drawer
                title="Menu"
                placement="right"
                onClose={toggleDrawer}
                open={drawerVisible}
                className="navbar__drawer navbar--mobile"
            >
                <ul className="navbar__drawer-menu navbar__drawer-menu--mobile">
                    <li className="navbar__drawer-link navbar__drawer-link--mobile"><span className="navbar__drawer-link-text"><Link to="/" onClick={toggleDrawer}>Home</Link></span></li>
                    <li className="navbar__drawer-link navbar__drawer-link--mobile"><span className="navbar__drawer-link-text"><Link to="/about" onClick={toggleDrawer}>About</Link></span></li>
                    <li className="navbar__drawer-link" onClick={() => toggleMobileMenu('Courses')}>
                        <span className={`navbar__drawer-link-text navbar__drawer-link-text--has-drop${activeMobileMenu === 'Courses' ? ' active' : ''}`}>CPD Courses</span>
                        {activeMobileMenu === 'Courses' ?
                        <ul className="navbar__drawer-drop-links">
                            {menuItems.filter(item => item.contentType === 'Course').map((item, index) => (
                                <li className="navbar__drawer-drop-link" key={index}>
                                    <span className="navbar__drop-link" onClick={() => handleCourseClick(item.alias?.alias)}>
                                        {item.displayText}
                                    </span>
                                </li>
                            ))}
                            {menuItems
                                .filter(item => item.contentType === 'Course')
                                .slice(0, 1).map((item, index) => (
                                    <li className="navbar__drawer-drop-link navbar__drawer-drop-link--all" key={index}>
                                        <Link to={`/${item.contentType.toLowerCase()}s`}><strong>View all {item.contentType}s</strong></Link>
                                    </li>
                                ))}
                        </ul>
                        : ''}
                    </li>
                    <li className="navbar__drawer-link" onClick={() => toggleMobileMenu('Resources')}>
                        <span className={`navbar__drawer-link-text navbar__drawer-link-text--has-drop${activeMobileMenu === 'Resources' ? ' active' : ''}`}>Resources</span>
                        {activeMobileMenu === 'Resources' ?
                        <ul className="navbar__drawer-drop-links">
                            {menuItems.filter(item => item.contentType === 'Resource').map((item, index) => (
                                <li className="navbar__drawer-drop-link" key={index}><Link to={`${process.env.REACT_APP_ORCHARD_API_URL}${item.resourceFile.urls[0]}`} target="_blank" onClick={toggleDrawer}>{item.displayText}</Link></li>
                            ))}
                            {menuItems
                                .filter(item => item.contentType === 'Resource')
                                .slice(0, 1).map((item, index) => (
                                    <li className="navbar__drawer-drop-link navbar__drawer-drop-link--all">
                                        <Link to={`/${item.contentType.toLowerCase()}s`} key={index}><strong>View all {item.contentType}s</strong></Link>
                                    </li>
                                ))}
                        </ul>
                        : ''}
                    </li>
                    <li className="navbar__drawer-link" onClick={() => toggleMobileMenu('Events')}>
                        <span className={`navbar__drawer-link-text navbar__drawer-link-text--has-drop${activeMobileMenu === 'Events' ? ' active' : ''}`}>Events</span>
                        {activeMobileMenu === 'Events' ?
                        <ul className="navbar__drawer-drop-links">
                            {menuItems.filter(item => item.contentType === 'Event').map((item, index) => (item.isItListed &&
                                <li className="navbar__drawer-drop-link" key={index}>
                                    <span className="navbar__drop-link" onClick={() => handleEventClick(item.alias?.alias)}>
                                        {item.displayText}
                                    </span>
                                </li>
                            ))}
                            {menuItems
                                .filter(item => item.contentType === 'Event')
                                .slice(0, 1).map((item, index) => (
                                    <li className="navbar__drawer-drop-link navbar__drawer-drop-link--all">
                                        <Link to={`/${item.contentType.toLowerCase()}s`} key={index}><strong>View all {item.contentType}s</strong></Link>
                                    </li>
                                ))}
                        </ul>
                        : ''}
                    </li>
                    <li className="navbar__drawer-link navbar__drawer-link--mobile"><span className="navbar__drawer-link-text"><Link to="/contact" onClick={toggleDrawer}>Get in touch</Link></span></li>
                </ul>
            </Drawer>
        </Layout.Header>
    );
};

export default TempNavbar;


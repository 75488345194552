import './EventList.scss';
import { Row, List, Card, Typography, Spin, Tabs } from 'antd';
import { Link } from 'react-router-dom';
import axios from "axios";
import { useEffect, useState } from 'react';

import { EventListItem } from '../../Utils/Types/EventTypes';
import { month } from '../../Utils/Helper/displayFunctions';

const EVENT_LIST_QUERY = `{
    event {
        contentItemId
        alias {
            alias
        }
        displayText
        isItListed
        displayImage {
            paths
            urls
        }
        eventVenue {
            displayName
            address1
            address2
            state
            city
            country
            postcode
        }
        startDate
        tags
      }
  }
  `;

const EventList: React.FC = (): JSX.Element => {

    const [eventList, setEventList] = useState<EventListItem[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const { TabPane } = Tabs;
    useEffect(() => {
        setIsLoading(true);

        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_ORCHARD_API_URL}/api/graphql`,
            headers: {
                'Content-Type': 'application/graphql'
            },
            data: EVENT_LIST_QUERY
        };

        axios(config).then(function (response) {
            const result = response.data.data.event;
            if (result) {
                setEventList(result.map((event: { tags: any[]; }) => ({
                    ...event,
                    tags: event.tags.map((tag: string) => tag.replace(/-/g, ' '))
                  })));
            }
            else {
                // TODO: no data front end
            }
        })
            .catch(function (error) {
                console.log(error);
            }).finally(() => {
                setIsLoading(false)
            });

    }, []);

    const uniqueTags = (eventList: EventListItem[]): string[] => {
        return [...new Set(eventList.flatMap(item => item.tags))];
      };

      console.log(eventList)
    
      const [activeTag, setActiveTag] = useState<string>(uniqueTags(eventList)[0] || '');
      
    
      useEffect(() => {
        if (eventList.length > 0) {
          setActiveTag(uniqueTags(eventList)[0]);
        }
      }, [eventList]);
    
      const handleTabChange = (key: string) => {
        setActiveTag(key);
      };
    

    return (
        <div>
        <div className="course-list-tabs row-padded">
          <h3 className="course-list-tabs__title">Filter results</h3>
          <Tabs defaultActiveKey={uniqueTags(eventList)[0]} onChange={handleTabChange}>
            {uniqueTags(eventList).map(tag => (
              <TabPane tab={tag} key={tag}>
        <Row className="row-padded">
            {isLoading ? <Spin /> : <List
                className="event-list"
                grid={{
                    gutter: 16,
                    column: 1,
                }}
                dataSource={eventList.filter(event => event.tags.includes(activeTag))}
                renderItem={(event: EventListItem) => (event.isItListed &&
                    <List.Item>
                        <Card 
                        className={`event-list_card ${event.tags.join(' ')}`}
                        style={{
                            background: event.displayImage?.urls?.length > 0 ? `linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url('${process.env.REACT_APP_ORCHARD_API_URL}${event.displayImage?.urls[0]}')` : 'white',
                            backgroundSize: 'cover',
                          }}>
                            <Card.Grid className="event-list__meta-detail event-list__meta-detail--date">
                                <Typography.Paragraph className="event-list__date">
                                    {event.startDate ? new Date(event.startDate).getDate() + " " + month[new Date(event.startDate).getMonth()] + "\n" : ''}
                                    {event.startDate ? new Date(event.startDate).getFullYear() : ''}</Typography.Paragraph>
                            </Card.Grid>
                            <Card.Grid className="event-list__meta-detail event-list__meta-detail--book-now">
                            <Typography.Title level={2} className="event-list__event-name" style={{color: event.displayImage?.urls?.length > 0 ? 'white' : 'black',}}>{event.displayText}</Typography.Title>
                                <Link className="event-list__meta-detail-btn" to={`/events/${event.alias.alias}`} style={{color: event.displayImage?.urls?.length > 0 ? 'white' : 'black',}}>Book now</Link>
                            </Card.Grid>
                            <Card.Grid className="event-list__meta-detail event-list__meta-detail--city">
                                <Typography.Paragraph className="event-list__city">{event.eventVenue.city}</Typography.Paragraph>
                            </Card.Grid>
                            <Card.Grid className="event-list__meta-detail">
                                <Typography.Paragraph className="event-list__address" style={{color: event.displayImage?.urls?.length > 0 ? 'white' : '#808080',}}>
                                    {event.eventVenue.displayName ? event.eventVenue.displayName + "\n" : ''}
                                    {event.eventVenue.address1 ? event.eventVenue.address1 + "\n" : ''}
                                    {event.eventVenue.address2 ? event.eventVenue.address2 + "\n" : ''}
                                    {event.eventVenue.state ? event.eventVenue.state + "\n" : ''}
                                    {event.eventVenue.city ? event.eventVenue.city + "\n" : ''}
                                    {event.eventVenue.postcode ? event.eventVenue.postcode + ", " : ''}
                                    {event.eventVenue.country ? event.eventVenue.country : ''}
                                </Typography.Paragraph>
                            </Card.Grid>
                        </Card>
                    </List.Item>
                )}
            />}
        </Row>
        </TabPane>
          ))}
        </Tabs>
      </div>
    </div>
    );
};

export default EventList;
import './CourseFormView.scss';
import { Form, Space, Button, Radio, Input, Checkbox, Alert, notification, Spin } from "antd";
import { CourseQuiz, Question, Option } from "../../../../Utils/Types/CourseTypes";
import axios from "axios";
import { getToken } from '../../../../MSALExtensions';
import { publicClientApplication } from '../../../..';
import { AuthScopes } from '../../../../AuthConfig';
import { useEffect, useState } from 'react';

interface courseContentViewProps {
    courseContent: CourseQuiz;
    nextStep: any;
    setCanNext: any;
}

const CourseFormView: React.FC<courseContentViewProps> = ({courseContent, nextStep, setCanNext}): JSX.Element => {
    const [api, contextHolder] = notification.useNotification();
    const [showSuccess, setShowSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();
    const formQuestions: Question[] = courseContent.quizQuestions.contentItems;

    const parseQuestion = (question: Question) => {
        let formItem = <Form.Item></Form.Item>;

        switch (question.contentType) {
            case "CourseQuizQuestion":
                if (question.multiChoiceAnswers) {
                    const options = question.multiChoiceAnswers.contentItems;

                    if (question.isMultipleAnswers) {
                        formItem = (<Form.Item name={question.contentItemId} label={question.courseQuestion.question} rules={[{ required: !question.courseQuestion.isOptional }]}>
                            <Checkbox.Group><Space direction="vertical">
                                {options.map((option: Option) =>
                                    <Checkbox value={option?.contentItemId}>
                                        {option?.quizMultiChoiceOption?.option}
                                    </Checkbox>
                                )}
                            </Space></Checkbox.Group>
                        </Form.Item>)
                    } else {
                        formItem = (<Form.Item name={question.contentItemId} label={question.courseQuestion.question} rules={[{ required: !question.courseQuestion.isOptional }]}>
                            <Radio.Group><Space direction="vertical">
                                {options.map((option: Option) =>
                                    <Radio value={option?.contentItemId}>
                                        {option?.quizMultiChoiceOption?.option}
                                    </Radio>
                                )}
                            </Space></Radio.Group>
                        </Form.Item>)
                    }
                }
                break;
            case "CourseOpenEndedQuestion":
                formItem = (<Form.Item label={question.courseQuestion.question} name={question.contentItemId} rules={[{ required: !question.courseQuestion.isOptional }]}>
                    <Input />
                </Form.Item>)
                break;
        }

        return formItem
    }

    const scoreQuiz = (answers: any) => {
        const result: any[] = [];

        for (const q in answers) {
            let questionId: string = q;
            let answer: any = answers[q];

            let questionDetails = formQuestions.find((q: Question) => q.contentItemId === questionId)

            if (questionDetails) {
                let wasCorrect = false
                
                if (questionDetails.courseQuestion.isOptional) {
                    wasCorrect = true;
                }
                else {
                    switch (questionDetails.contentType)
                    {
                        case "CourseQuizQuestion":
                            
                            const correct: any = questionDetails.multiChoiceAnswers?.contentItems.filter((a: Option) => a.quizMultiChoiceOption.isCorrect).map((a: Option) => a.contentItemId)

                            if (questionDetails.isMultipleAnswers) {
                                // note: answers need to be exact
                                if (JSON.stringify(correct) === JSON.stringify(answer)) {
                                    wasCorrect = true;
                                }
                            }
                            else {
                                if (correct?.includes(answer))
                                {
                                    wasCorrect = true;
                                }
                            }
                     
                            break;
                        case "CourseOpenEndedQuestion":
                            // TODO: parse if question is optional
                            if (answer && answer !== "")
                            {
                                wasCorrect = true;
                            }
                            break;
                    }
                }
                
                result.push({
                    questionId: questionId,
                    wasCorrect: wasCorrect,
                })
            }
        }

        return result;
    }

    const onFinish = async (values: any) => {
        setLoading(true);

        const toSubmit = {
           questions: scoreQuiz({... values})
        };
        
        const accessToken = await getToken(publicClientApplication, AuthScopes.QuizApi);
        const headers = {
            Authorization: `Bearer ${accessToken}`,
        };

        await axios.post(`${process.env.REACT_APP_ACADEMY_QUIZ_API}/api/Quiz/${courseContent.contentItemId}`, toSubmit, { headers })
            .then((response) => {
                if (response.status === 200) {
                    // TODO: show results
                    setShowSuccess(true);
                    setCanNext(true);
                }
            })
            .catch((error) => {
                console.log(error);
                api['error']({
                    message: 'Error submitting your quiz answers',
                    description:
                      'There is a problem submitting your quiz answers. Please try again later.',
                  });
            })
            .finally(() => { setLoading(false) });
        
    }
    
    return (
        <>
            <Spin spinning={loading}>
                <Form form={form} onFinish={onFinish} layout="vertical" style={{ width: '100%' }} wrapperCol={{ span: 16 }}>
                    <>
                        {
                            formQuestions.map((question: Question) => {
                                return parseQuestion(question)
                            })
                        }
                    </>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="submit-form-btn">Submit</Button>
                    </Form.Item>
                    {showSuccess && <Alert message="Answers submitted successfuly! Proceed to the next step." type="success" showIcon closable/>}
                </Form>
            </Spin>
        </>
    );
};

export default CourseFormView;
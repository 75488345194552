import './UserButton.scss';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Button, Dropdown, MenuProps } from "antd";
import { useEffect, useState } from "react";
import { signInHandler, signOutHandler } from "../../../Utils/Helper/msalFunctions";
import { ReactComponent as LAUser } from '../../../Utils/Assets/icon_user.svg';
import { publicClientApplication } from '../../..';
import { isAdminUser } from '../../../MSALExtensions';

const SignIn = (): JSX.Element => {
    const { instance } = useMsal();

    return (
        <Button className="sign-in-icon" onClick={async () => await signInHandler(instance)}>
            <div className="sign-in-icon--mobile"><LAUser /></div>
            <div className="sign-in-icon--desktop">Log In</div>
        </Button>
    );
};

const SignOut = (): JSX.Element => {
    const { instance, accounts } = useMsal();
    const [account, setAccount] = useState(null as any);

    const pca = publicClientApplication;
    const isAdmin = isAdminUser(pca);

    const items: MenuProps['items'] = !isAdmin ? [
        {
            key: '1',
            label: (
                <a href={`/profile`}>
                    Profile
                </a>),
        },
        {
          key: '2',
          danger: true,
          label: (
            <span onClick={() => signOutHandler(instance)}>
                Log Out
            </span>
          ),
        },
      ] :
      [  {
            key: '1',
            label: (
                <a href={`/profile`}>
                    Profile
                </a>),
        },
        {
            key: '2',
            label: (
              <a href={'/admin-panel'}>
                Admin Panel
              </a>
            ),
          },
        {
          key: '3',
          danger: true,
          label: (
            <span onClick={() => signOutHandler(instance)}>
                Log Out
            </span>
          ),
        },
      ];

    const accountName = (name: string | undefined): string => {
        if (!name) return 'N/A';
        try {
            const firstName = name.split(" ")[0];
            if (firstName.length < 10) {
                return firstName
            }
            else {
                return name.split(" ").map((n: any)=>n[0]).join(".");
            }
        } catch (e) {
            console.log(e);
            return 'User';
        }
    }
    

    useEffect(() => {
        if (accounts.length > 0) {
            setAccount(accounts[0]);
        }
    }, []);

    return (
        <Dropdown menu={{items}} placement="bottom" arrow>
            <Button className="sign-in-icon">
                {accountName(account?.name)}
            </Button>
        </Dropdown>
    );
};

const UserButton: React.FC = (): JSX.Element => {

    return (
    <>
        <UnauthenticatedTemplate>
            <SignIn />
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
            <SignOut />
        </AuthenticatedTemplate>
    </>
    )
        
};

export default UserButton;
import './YourEvents.scss';
import axios from "axios";
import { publicClientApplication } from '../../..';
import { AuthScopes } from '../../../AuthConfig';
import { getToken } from '../../../MSALExtensions';

import { Row, Typography, Card, List, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { EventListItem } from '../../../Utils/Types/EventTypes';
import { month } from '../../../Utils/Helper/displayFunctions';



const YourEvents: React.FC = (): JSX.Element => {
    const [eventList, setEventList] = useState([] as any);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            try {
              const accessToken = await getToken(publicClientApplication, AuthScopes.EventApi);
              const headers = {
                Authorization: `Bearer ${accessToken}`,
              };
              
              const response = await axios.get(`${process.env.REACT_APP_ACADEMY_EVENT_API}/Event/me`, { headers });

              if (response.status === 200 && response.data) {
                  
                  const eL = response.data;
                  const config = (query: any) => ({
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${process.env.REACT_APP_ORCHARD_API_URL}/api/graphql`,
                    headers: { 
                      'Content-Type': 'application/graphql'
                    },
                    data: query,
                  });
           
                  // Use Promise.all to ensure all queries are resolved
                  const eventPromises = eL.map((i: any) => {
                    const YOUR_EVENTS_QUERY = `{
                      event(where: {contentItemId: "${i.eventId}"}) {
                        contentItemId
                        alias {
                            alias
                        }
                        displayText
                        isItListed
                        displayImage {
                            paths
                            urls
                        }
                        eventVenue {
                            displayName
                            address1
                            address2
                            state
                            city
                            country
                            postcode
                        }
                        startDate
                      }
                    }`;
             
                    return axios(config(YOUR_EVENTS_QUERY)).then((response) => {
                      if (response.status === 200 && response.data.data) {
                        const result = response.data.data.event[0];
                        return result || null;
                      }
                      else {
                        return null;
                      }
                    }).catch((error) => {
                      console.error(error);
                      return null; // Handle error by returning null or default value
                    });
                  });
           
                  // Wait for all courses to be fetched
                  const events = await Promise.all(eventPromises);
                  // Filter out null results
                  const filteredEvents = events.filter(event => event !== null);

                  setEventList(filteredEvents);
                  setIsLoading(false)
              } else {
                console.error("Failed to fetch event details");
                setIsLoading(false)
              }
            } catch (error) {
              console.error("Error fetching event data: ", error);
              setIsLoading(false)
            }
          })();
    }, []);

 


  return (
    <Row className="row-padded profile your-courses">
    <Row className="profile__bg" justify="center" align="middle">
      <Typography.Title className="your-courses-section-title" level={2}>Your Events</Typography.Title>
      <Typography.Link className="your-courses__link" href="/events">View all events</Typography.Link>
      <Row className='row-your-events'>
      {isLoading ? <Spin /> : <List
                className="event-list"
                grid={{
                    gutter: 16,
                    column: 1,
                }}
                dataSource={eventList}
                renderItem={(event: EventListItem) => (event.isItListed &&
                    <List.Item>
                        <Card className="event-list_card" style={{
                            background: event.displayImage?.urls?.length > 0 ? `linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url('${process.env.REACT_APP_ORCHARD_API_URL}${event.displayImage?.urls[0]}')` : 'white',
                            backgroundSize: 'cover',
                          }}>
                            <Card.Grid className="event-list__meta-detail event-list__meta-detail--date">
                                <Typography.Paragraph className="event-list__date">
                                    {event.startDate ? new Date(event.startDate).getDate() + " " + month[new Date(event.startDate).getMonth()] + "\n" : ''}
                                    {event.startDate ? new Date(event.startDate).getFullYear() : ''}</Typography.Paragraph>
                            </Card.Grid>
                            <Card.Grid className="event-list__meta-detail event-list__meta-detail--book-now">
                            <Typography.Title level={2} className="event-list__event-name" style={{color: event.displayImage?.urls?.length > 0 ? 'white' : 'black',}}>{event.displayText}</Typography.Title>
                            <Link className="event-list__meta-detail-btn" to={`/events/${event.alias.alias}`} style={{color: event.displayImage?.urls?.length > 0 ? 'white' : 'black',}}>Book now</Link>
                        </Card.Grid>
                        <Card.Grid className="event-list__meta-detail event-list__meta-detail--city">
                            <Typography.Paragraph className="event-list__city">{event.eventVenue.city}</Typography.Paragraph>
                        </Card.Grid>
                        <Card.Grid className="event-list__meta-detail">
                            <Typography.Paragraph className="event-list__address" style={{color: event.displayImage?.urls?.length > 0 ? 'white' : '#808080',}}>
                                {event.eventVenue.displayName ? event.eventVenue.displayName + "\n" : ''}
                                {event.eventVenue.address1 ? event.eventVenue.address1 + "\n" : ''}
                                {event.eventVenue.address2 ? event.eventVenue.address2 + "\n" : ''}
                                {event.eventVenue.state ? event.eventVenue.state + "\n" : ''}
                                {event.eventVenue.city ? event.eventVenue.city + "\n" : ''}
                                {event.eventVenue.postcode ? event.eventVenue.postcode + ", " : ''}
                                {event.eventVenue.country ? event.eventVenue.country : ''}
                            </Typography.Paragraph>
                        </Card.Grid>
                    </Card>
                </List.Item>
            )}
        />}
      </Row>
    </Row>
    </Row>
    );
};

export default YourEvents;

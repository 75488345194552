import './HomeAbout.scss';
import { Row, Col } from 'antd';
import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import axios from "axios";
import { HomepageCopies } from '../../../Utils/Types/CopyTypes';

const HomeAbout: React.FC = (): JSX.Element => {
    const { instance } = useMsal();
    // TODO: Make sure user is logged out on clicking register button

    const COPY_QUERY = `{
        homepageCopies(first: 1) {
            aboutLucecoAcademyBody {
              html
            }
            aboutLucecoAcademyImage {
              urls
            }
            aboutLucecoAcademyTitle
            cPDCertificationBody {
              html
            }
            cPDCertificationImage {
              urls
            }
            cPDCertificationTitle
          }
      }`;

    const [homepageCopy, setHomepageCopy] = useState({} as HomepageCopies);

    useEffect(() => {
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_ORCHARD_API_URL}/api/graphql`,
            headers: {
                'Content-Type': 'application/graphql'
            },
            data: COPY_QUERY
        };

        axios(config).then(function (response: any) {
            const result = response.data.data.homepageCopies;
            if (result) {
                setHomepageCopy(result[0]);
            }
        });
    }, []);

    return (
        <Row justify="center">
            <Col className="cpd-image">
                <a href="https://cpduk.co.uk/" target="_blank"><img src="/images/CPD-logo.png" alt="CPD Logo" /></a>
            </Col>
        </Row>

    );
};

export default HomeAbout;
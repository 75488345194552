import { Button, Input, Select, Form, notification } from "antd";
import type { FormProps } from 'antd';

import useActiveEvents from "../hooks/useActiveEvents";
import { useState } from "react";
import { getToken } from "../../../MSALExtensions";
import { publicClientApplication } from "../../..";
import { AuthScopes } from "../../../AuthConfig";
import axios from "axios";

type AdminAttendanceForm = {
    eventId: string;
    firstName: string;
    lastName: string;
    email: string;
};

const AddAttendance = () => {
    const activeEvents = useActiveEvents();
    const [api, contextHolder] = notification.useNotification();
    
    const [loading, setLoading] = useState(false);
    const [attendanceForm] = Form.useForm();

    const formSubmission: FormProps<AdminAttendanceForm>['onFinish'] = async (values) => {
        // TODO: Post values to api
        console.log(values);

        setLoading(true);
                
        const accessToken = await getToken(publicClientApplication, AuthScopes.EventApi);
        const headers = {
            Authorization: `Bearer ${accessToken}`,
        };

        const toSubmit = {
            FirstName: values.firstName,
            LastName: values.lastName,
            Email: values.email,
          }

        await axios.post(`${process.env.REACT_APP_ACADEMY_EVENT_API}/Attendance/${values.eventId}/onbehalf/${values.email}`, toSubmit, { headers })
            .then((response) => {
                if (response.status === 200) {
                    // TODO: show results
                    api['success']({
                        message: 'Success!',
                        description: 'Attendance is recorded.',
                        placement: 'bottom'
                      });
                }
            })
            .catch((error) => {
                console.log(error);
                api['error']({
                    message: 'Error recording attendance',
                    description:
                      'Please try again later.',
                  });
            })
            .finally(() => { setLoading(false) });
            

        attendanceForm.resetFields();
    };
    return (
        <>
        <Form
            name='admin-attendance-submission'
            form={attendanceForm}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            onFinish={formSubmission}
            layout="vertical"
            autoComplete='off'>
            {contextHolder}
            <Form.Item<AdminAttendanceForm> name='eventId' required label="Event">
                <Select placeholder='Event' allowClear>
                    {activeEvents.recentEvents?.map((e: any) => (
                        <Select.Option value={e.contentItemId}>{e.displayText}</Select.Option>
                    ))};
                </Select>
            </Form.Item>

            <Form.Item<AdminAttendanceForm> name='firstName' label="First Name">
                <Input id='event-person' placeholder='First Name' />
            </Form.Item>
            <Form.Item<AdminAttendanceForm> name='lastName' label="Last Name">
                <Input id='event-person' placeholder='Last Name' />
            </Form.Item>

            <Form.Item<AdminAttendanceForm> name='email' label="Email Address" required>
                <Input id='event-email' placeholder='Email Address'/>
            </Form.Item>

            <Form.Item<AdminAttendanceForm>>
                <Button type='primary' htmlType='submit'>Submit Attendance</Button>
            </Form.Item>
        </Form>
        </>
    );
};

export default AddAttendance;
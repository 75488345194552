import { Collapse, Row } from 'antd';
import { Faq } from '../../../Utils/Types/EventTypes';
import './EventFaqView.scss';
import parse from 'html-react-parser';
import { getTextColor } from '../../../Utils/Helper/displayFunctions';
import styled from 'styled-components';
import { text } from 'stream/consumers';

interface EventVars {
    contentItems: Faq[];
    colorTheme: string;
}

const EventFAQView: React.FC<EventVars> = ({colorTheme, contentItems}): JSX.Element => {
  const textThemeColor = getTextColor(colorTheme);

  const StyledCollapse = styled(Collapse.Panel)`
    &&& {
      border: none;
      border-radius: 0px;
      box-shadow: none;  
      background: ${colorTheme};
      width: 100%;
      font-weight: 600;
    }
    .ant-collapse-header {
      color: ${textThemeColor} !important;
      text-align: center;
    }
  `;

    return (
        <Row className="event-faq">
          <Collapse className="collapse collapse-container"
              bordered={false}
              style={{ width: '100%' }}
          >
            <StyledCollapse header="Frequently Asked Questions" key="1">
              <Collapse className="collapse collapse-body" style={{
                fontWeight: '400', borderColor: textThemeColor}}>
                {
                  contentItems.map((f: Faq) => { return (
                    <Collapse.Panel key={f.contentItemId} header={f.question} style={{ borderColor: textThemeColor}}>
                      {parse(f.answer.html)}
                    </Collapse.Panel>
                  )})
                }
              </Collapse>
            </StyledCollapse>   
          </Collapse>
        </Row>
    );
};

export default EventFAQView;
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import Profile from '../Components/Profile/Profile';
import PageHeader from '../Components/PageHeader/PageHeader';

function ProfilePage() {

    const { instance, accounts } = useMsal();
    const [account, setAccount] = useState(null as any);

    useEffect(() => {
        if (accounts.length > 0) {
            setAccount(accounts[0]);
        }
    }, []);

    return (
        <>
            <PageHeader title="Profile" bannerUrl="./images/test-images/joinluceco.jpg" desc={null}/>
            <AuthenticatedTemplate>
                <Profile account={account}/>
            </AuthenticatedTemplate>
        </>
    );
}

export default ProfilePage;
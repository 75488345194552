import { IPublicClientApplication } from "@azure/msal-browser";
import { B2CPolicies } from "../../AuthConfig";

export async function signInHandler(instance: IPublicClientApplication) {

  const loginRequest = {
    authority: B2CPolicies.Authorities.SignUpSignIn.Authority,
    scopes: [],
  };

  await instance.loginRedirect(loginRequest);
}

export function signOutHandler(instance: IPublicClientApplication) {
    const logoutRequest = {
      account: instance.getActiveAccount(),
      postLogoutRedirectUri: ''
    };

    instance.logoutRedirect(logoutRequest);
}
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { ApolloClient, InMemoryCache, ApolloProvider, gql, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { RouterProvider } from "react-router-dom";
import { router } from './router';

import './index.scss';
import { AuthScopes, MSALConfig } from './AuthConfig';
import { getToken } from './MSALExtensions';

export const publicClientApplication = new PublicClientApplication(MSALConfig);

publicClientApplication.initialize().then(() => {

  const accounts = publicClientApplication.getAllAccounts();
  if (accounts.length > 0) {
    publicClientApplication.setActiveAccount(accounts[0]);
  }

  publicClientApplication.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      publicClientApplication.setActiveAccount(account);
    }
  });

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_ORCHARD_API_URL}/api/graphql`
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = await getToken(publicClientApplication, AuthScopes.Orchard);

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
    }
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    //link: httpLink,
    cache: new InMemoryCache(),
    connectToDevTools: true
  });



  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  root.render(
    <React.StrictMode>
      <MsalProvider instance={publicClientApplication}>
        <ApolloProvider client={client}>
          <RouterProvider router={router} />
        </ApolloProvider>
      </MsalProvider>
    </React.StrictMode>
  );
});
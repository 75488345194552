import './Profile.scss'

import React from 'react';
import YourCourses from './YourCourses/YourCourses';
import { Row, Typography } from 'antd';
import YourEvents from './YourEvents/YourEvents';
import ApiTest from '../../Pages/ApiTest';
import AdminPanel from '../AdminPanel/AdminPanel';
interface Props {
    account?: any;
}


const Profile: React.FC<Props> = ({ account }): JSX.Element => {

  return (
    <>
    <Row className="row-padded profile">
      <Row className="profile__bg" justify="center" align="middle">
        <Typography.Title className="profile-title" level={2}>{account?.name}</Typography.Title>
        <Typography.Paragraph className="profile__details">
           {account?.idTokenClaims.email}
        </Typography.Paragraph>
          <Typography.Link className="profile__msal-button" href={`https://lucecoacademy.b2clogin.com/lucecoacademy.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_PROFILEEDIT&response_type=id_token&scope=openid&client_id=755e9af2-7a7b-4cd5-9560-c20408ae5f40&redirect_uri=${window.origin}`}>Edit Account Details</Typography.Link>
      </Row>
    </Row>
    <YourCourses />
      <YourEvents />
    </>
  );
};

export default Profile;
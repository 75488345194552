import { useEffect, useRef, useState } from 'react';
import { CourseContent, CourseCustomIframeContent, CourseIframeContent } from '../../../../Utils/Types/CourseTypes';
import parse from 'html-react-parser';

interface courseIframeContentViewProps {
    iframeContent: CourseContent;
    flag: string;
}

const CourseIframeContentView: React.FC<courseIframeContentViewProps> = ({iframeContent, flag}): JSX.Element => {
    const iref = useRef<HTMLIFrameElement>(null);
    const [height, setHeight] = useState('0px');

    const onIframeLoad = () => {
        let scrollHeight = iref?.current?.contentWindow?.document.body.scrollHeight;
        console.log(scrollHeight);
        if (!scrollHeight || scrollHeight < 420) scrollHeight = 1400; // accounting for zoho scrollheight errors
        setHeight(scrollHeight + 'px');
    };
    
    useEffect(() => {
        onIframeLoad();
      }, []);
    
    // separate this into component(s)
    const parseContent = () => {
        let content = <></>

        switch (flag) {
            case "CourseIframeContent":
                const ic = (iframeContent as CourseIframeContent);
                content = <iframe
                    ref={iref}
                    onLoad={onIframeLoad}
                    width="100%"
                    height={height}
                    src={ic.sourceLink?.url}
                    title={ic.displayText}
                />
                break;
            case "CourseCustomIframeContent":
                const cic= (iframeContent as CourseCustomIframeContent);
                const cIframe: string = (cic.htmlBody.html.substring(0,7) === "<iframe") ? cic.htmlBody.html : "";
                content = <>{parse(cIframe)}</>;
                break;
            default:
                break;
        }

        return content;
    }

    return (
        <>
            {parseContent()}
        </>
    );
};

export default CourseIframeContentView;
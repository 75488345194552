import { useEffect, useState } from "react";
import PageHeader from "../Components/PageHeader/PageHeader";
import ResourcesList from "../Components/ResourcesList/ResourcesList";
import axios from "axios";

function ResourcesPage() {
    const RESOURCES_COPY_QUERY = `{
        pageCopies(first: 1) {
            resourcesPageCopy {
            html
            markdown
          }
        }
      }`

      const [resourcePageCopy, setResourceListPageCopy] = useState("");

    useEffect(() => {
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_ORCHARD_API_URL}/api/graphql`,
            headers: { 
              'Content-Type': 'application/graphql'
            },
            data : RESOURCES_COPY_QUERY
        };
          
        axios(config).then(function (response: any) {
            const result = response.data.data?.pageCopies[0]?.resourcesPageCopy?.html;
          if (result) {
            setResourceListPageCopy(result);
          }
          else {
              // TODO: no data front end
          }
        });
    });
    
    return (<>
        <PageHeader title="Resources" bannerUrl="./images/test-images/industest.webp" desc={resourcePageCopy}/>
        <ResourcesList />
    </>);
}

export default ResourcesPage;

import { useEffect, useState } from "react";
import EventList from "../Components/EventList/EventList";
import PageHeader from "../Components/PageHeader/PageHeader";
import axios from "axios";

function EventsPage() {
    const EVENTSPAGE_COPY_QUERY = `{
        pageCopies(first: 1) {
            eventsListPageCopy {
                html
                markdown
            }
        }
      }`

      const [eventsPageCopy, setEventsPageCopy] = useState("");

    useEffect(() => {
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_ORCHARD_API_URL}/api/graphql`,
            headers: { 
              'Content-Type': 'application/graphql'
            },
            data : EVENTSPAGE_COPY_QUERY
        };
          
        axios(config).then(function (response: any) {
            const result = response.data.data?.pageCopies[0]?.eventsListPageCopy?.html;
          if (result) {
            setEventsPageCopy(result);
          }
          else {
              // TODO: no data front end
          }
        });
    });

    return (
        <>
            <PageHeader title="Events" bannerUrl="./images/test-images/zoomi.jpg" desc={eventsPageCopy}/>
            <EventList />
        </>
    );
}


export default EventsPage;
export const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

export const getColorTag = (tagName: string) => {
    switch (tagName) {
        case 'featured':
            return 'gold';
        case 'cpd':
            return 'blue';
        case 'efixx':
            return 'red';
        case 'pdf':
            return 'gold';
        default:
            return 'green';
    }
}

const componentToHex = (c: number) => {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }
  
export const rgbToHex = (r: number, g:number, b:number) => {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export const hexToRgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      red: parseInt(result[1], 16),
      green: parseInt(result[2], 16),
      blue: parseInt(result[3], 16)
    } : {
        red: 0,
        green: 0,
        blue: 0
      };
}
  
export const getTextColor = (hex: string) => {
    const rgb = hexToRgb(hex);
    const calc = rgb ? (rgb.red * 0.299 + rgb.green * 0.587 + rgb.blue *0.114) : null
    if (calc && calc <= 186)
      return "white";
    else
      return "black";
}

const rgb2xyz = (r: number, g: number, b: number, a = 1) => {
    if (r > 255) {
        // console.warn("Red value was higher than 255. It has been set to 255.");
        r = 255;
    } else if (r < 0) {
        // console.warn("Red value was smaller than 0. It has been set to 0.");
        r = 0;
    }
    if (g > 255) {
        // console.warn("Green value was higher than 255. It has been set to 255.");
        g = 255;
    } else if (g < 0) {
        // console.warn("Green value was smaller than 0. It has been set to 0.");
        g = 0;
    }
    if (b > 255) {
        // console.warn("Blue value was higher than 255. It has been set to 255.");
        b = 255;
    } else if (b < 0) {
        // console.warn("Blue value was smaller than 0. It has been set to 0.");
        b = 0;
    }
    if (a > 1) {
        // console.warn("Obacity value was higher than 1. It has been set to 1.");
        a = 1;
    } else if (a < 0) {
        // console.warn("Obacity value was smaller than 0. It has been set to 0.");
        a = 0;
    }
    r = r / 255;
    g = g / 255;
    b = b / 255;
    // step 1
    if (r > 0.04045) {
        r = Math.pow(((r + 0.055) / 1.055), 2.4);
    } else {
        r = r / 12.92;
    }
    if (g > 0.04045) {
        g = Math.pow(((g + 0.055) / 1.055), 2.4);
    } else {
        g = g / 12.92;
    }
    if (b > 0.04045) {
        b = Math.pow(((b + 0.055) / 1.055), 2.4);
    } else {
        b = b / 12.92;
    }
    // step 2
    r = r * 100;
    g = g * 100;
    b = b * 100;
    // step 3
    const x = (r * 0.4124564) + (g * 0.3575761) + (b * 0.1804375);
    const y = (r * 0.2126729) + (g * 0.7151522) + (b * 0.0721750);
    const z = (r * 0.0193339) + (g * 0.1191920) + (b * 0.9503041);
    return [x, y, z];
}

const xyz2rgba = (x: number, y: number, z: number) => {
    let varX = x / 100;
    let varY = y / 100;
    let varZ = z / 100;

    let varR = (varX *  3.2404542) + (varY * -1.5371385) + (varZ * -0.4985314);
    let varG = (varX * -0.9692660) + (varY *  1.8760108) + (varZ * 0.0415560);
    let varB = (varX *  0.0556434) + (varY * -0.2040259) + (varZ * 1.0572252);

    if ( varR > 0.0031308 ) {
        varR = 1.055 * Math.pow(varR, (1 / 2.4) ) - 0.055;
    } else {
        varR = 12.92 * varR;
    }
    if ( varG > 0.0031308 ) {
        varG = 1.055 * Math.pow(varG, (1 / 2.4) ) - 0.055;
    } else {
        varG = 12.92 * varG;
    }
    if ( varB > 0.0031308 ) {
        varB = 1.055 * Math.pow(varB, (1 / 2.4) ) - 0.055;
    } else {
        varB = 12.92 * varB;
    }

    let r = Math.round(varR * 255);
    let g = Math.round(varG * 255);
    let b = Math.round(varB * 255);

    return [r, g, b, 1];
}

const lab2xyz = (l: number, a: number, b: number) => {
    // using 10o Observer (CIE 1964)
    // CIE10_D65 = {94.811f, 100f, 107.304f} => Daylight
    const referenceX = 94.811;
    const referenceY = 100;
    const referenceZ = 107.304;

    let varY = ( l + 16 ) / 116;
    let varX = a / 500 + varY;
    let varZ = varY - b / 200;

    if ( Math.pow(varY, 3)  > 0.008856 ) {
        varY = Math.pow(varY, 3);
    } else {
        varY = ( varY - 16 / 116 ) / 7.787;
    }
    if ( Math.pow(varX, 3)  > 0.008856 ) {
        varX = Math.pow(varX, 3);
    } else {
        varX = ( varX - 16 / 116 ) / 7.787;
    }
    if ( Math.pow(varZ, 3)  > 0.008856 ) {
        varZ = Math.pow(varZ, 3);
    } else {
        varZ = ( varZ - 16 / 116 ) / 7.787;
    }

    let x = varX * referenceX;
    let y = varY * referenceY;
    let z = varZ * referenceZ;

    return [x, y, z];
}

const xyz2lab = (x: number, y: number, z: number) => {
    // using 10o Observer (CIE 1964)
    // CIE10_D65 = {94.811f, 100f, 107.304f} => Daylight
    const referenceX = 94.811;
    const referenceY = 100;
    const referenceZ = 107.304;
    // step 1
    x = x / referenceX;
    y = y / referenceY;
    z = z / referenceZ;
    // step 2
    if (x > 0.008856) {
        x = Math.pow(x, (1 / 3));
    } else {
        x = (7.787 * x) + (16 / 116);
    }
    if (y > 0.008856) {
        y = Math.pow(y, (1 / 3));
    } else {
        y = (7.787 * y) + (16 / 116);
    }
    if (z > 0.008856) {
        z = Math.pow(z, (1 / 3));
    } else {
        z = (7.787 * z) + (16 / 116);
    }
    // step 3
    const l = (116 * y) - 16;
    const a = 500 * (x - y);
    const b = 200 * (y - z);
    return [l, a, b];
}

const rgba2lab = (r: number, g: number, b: number, a = 1) => {
    const [x, y, z] = rgb2xyz(r, g, b, a);
    return xyz2lab(x, y, z); // [l, a, b]
}

const lab2rgba = (l: number, a: number, b: number) => {
    const [x, y, z] = lab2xyz(l, a, b);
    return xyz2rgba(x, y, z); // [r, g, b, a]
}

const getDarkerColour = (r: number, g: number, b: number, a = 1, darkenPercentage = 0.05) => {
    let [l1, a1, b1] = rgba2lab(r, g, b, a);
    l1 -= l1 * darkenPercentage;
    if (l1 < 0) {
        l1 = 0;
    }
    return lab2rgba(l1, a1, b1); // [R, G, B, A]
}
/**
 * Get brighter colour of the given colour
 * @param {[Number]} r     Red value from 0 to 255
 * @param {[Number]} g     Green value from 0 to 255
 * @param {[Number]} b     Blue value from 0 to 255
 */
const getBrighterColour = (r: number, g: number, b: number, a = 1, brighterPercentage = 0.05) => {
    let [l1, a1, b1] = rgba2lab(r, g, b, a);
    l1 += l1 * brighterPercentage;
    if (l1 > 100) {
        l1 = 100;
    }
    return lab2rgba(l1, a1, b1); // [R, G, B, A]
}

export const getReadableColour = (hex: string, isLightBG: boolean) => {
    try {
        const rgb = hexToRgb(hex);
        const calc = rgb ? (rgb.red * 0.299 + rgb.green * 0.587 + rgb.blue *0.114) : null
        
        if (isLightBG) {
            if (calc && calc <= 186)
            {
                // colour is dark, vis OK, return original hex
                return hex;
            }
            else {
                // colour is light, change to dark colour
                let newColour = getDarkerColour(rgb.red, rgb.green, rgb.blue);
                return rgbToHex(newColour[0], newColour[1], newColour[2])
            }
        }
        else {
            if (calc && calc <= 186)
                {
                    // colour is dark, change to lighter colour
                    let newColour = getBrighterColour(rgb.red, rgb.green, rgb.blue);
                    return rgbToHex(newColour[0], newColour[1], newColour[2])
                }
                else {
                    // colour is light vis OK
                    return hex;
                }
        }
    }
    catch (e){
        return null;
    }
}
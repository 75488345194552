import './LucecoFooter.scss';
import { Layout, Row, Col, Space, Button, Typography } from 'antd';

import { ReactComponent as LucecoGroupSVG } from '../../Utils/Assets/Logos/luceco-group.svg';
import { ReactComponent as BGElectricalSVG } from '../../Utils/Assets/Logos/BGElectrical.svg';
import { ReactComponent as DWWindsorSVG } from '../../Utils/Assets/Logos/DWWindsor.svg';
import { ReactComponent as KingfisherSVG } from '../../Utils/Assets/Logos/Kingfisher.svg';
import { ReactComponent as LucecoLightingSVG } from '../../Utils/Assets/Logos/LucecoLighting.svg';
import { ReactComponent as MasterplugSVG } from '../../Utils/Assets/Logos/Masterplug.svg';
import { ReactComponent as NexusSVG } from '../../Utils/Assets/Logos/Nexus.svg';
import { ReactComponent as RossSVG } from '../../Utils/Assets/Logos/Ross.svg';
import { ReactComponent as SyncEVSVG } from '../../Utils/Assets/Logos/SyncEV.svg';
import { FacebookOutlined, InstagramOutlined, YoutubeFilled } from '@ant-design/icons';

const LucecoFooter: React.FC = (): JSX.Element => {

    return (
        <Layout.Footer>
            <Row className="luceco-footer">
                <Space direction="vertical" className="luceco-footer__items">
                    <Row>
                        <LucecoGroupSVG className="luceco-footer__logo" onClick={() => window.location.href = "https://www.lucecoplc.com/"} />
                    </Row>
                    <Row className="luceco-footer__links">
                        <Col><Typography.Link className="luceco-footer__link" href="/privacy">Privacy Policy</Typography.Link></Col>
                        <Col><Typography.Link className="luceco-footer__link" href="/contact">Contact</Typography.Link></Col>
                    </Row>
                    <Row justify="space-between">
                        <Col>
                            <Typography.Paragraph className="luceco-footer__copyright">
                                Luceco is a registered trademark and member of the Luceco plc group of companies. © Copyright {new Date().getFullYear()} Luceco Group
                            </Typography.Paragraph>
                        </Col>
                        <Col>
                            <Space>
                                <Button
                                    className="luceco-footer__social"
                                    icon={<InstagramOutlined />}
                                    href='https://www.instagram.com/luceco_academy/'
                                    target="_blank"
                                />
                                <Button
                                    className="luceco-footer__social"
                                    icon={<FacebookOutlined />}
                                    href="https://www.facebook.com/lucecoacademy"
                                    target="_blank"
                                />
                                <Button
                                    className="luceco-footer__social"
                                    icon={<YoutubeFilled />}
                                    href="https://www.youtube.com/@lucecogroup"
                                    target="_blank"
                                />
                            </Space>
                        </Col>
                    </Row>
                </Space>

                {/*<Col flex="1 0 40%" className="footer-section luceco-footer-2">
                    <Row className="luceco-brands-label">Luceco Group</Row>
                    <div className="luceco-brands-section">
                        <BGElectricalSVG onClick={() => window.location.href = "https://www.bgelectrical.uk/"}/>
                        <SyncEVSVG onClick={() => window.location.href = "https://syncev.co.uk/"}/>
                        <LucecoLightingSVG onClick={() => window.location.href = "https://www.luceco.com/"}/>
                        <MasterplugSVG onClick={() => window.location.href = "https://masterplug.com/uk"}/>
                        <KingfisherSVG onClick={() => window.location.href = "https://www.kingfisherlighting.com/"}/>
                        <DWWindsorSVG onClick={() => window.location.href = "https://www.dwwindsor.com/"}/>
                        <NexusSVG onClick={() => window.location.href = "https://nexus.uk/"}/>
                        <RossSVG onClick={() => window.location.href = "https://ross.uk/"}/>
                    </div>
                </Col>*/}
                <Col className="luceco-footer-3" flex="1 0 30%" >

                </Col>
            </Row>
        </Layout.Footer>
    );
};


export default LucecoFooter;
import { useEffect, useState } from "react";
import PageHeader from "../Components/PageHeader/PageHeader";
import parse from 'html-react-parser';
import axios from "axios";
import { Row, Typography } from "antd";

function PrivacyPage() {
    const PRIVACY_QUERY = `{
        privacyPolicy(first:1){
            contentItemId
            displayText
            privacyPolicyBody {
              html
              markdown
            }
          }
      }`

      const [privacyPolicy, setPrivacyPolicy] = useState("");
      const [displayTitle, setDisplayTitle] = useState("Privacy Policy");

    useEffect(() => {
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_ORCHARD_API_URL}/api/graphql`,
            headers: { 
              'Content-Type': 'application/graphql'
            },
            data : PRIVACY_QUERY
        };
          
        axios(config).then(function (response: any) {
            const result = response.data.data?.privacyPolicy[0];
          if (result) {
            setDisplayTitle(result.displayText);
            setPrivacyPolicy(result.privacyPolicyBody?.html);
          }
          else {
              // TODO: no data front end
          }
        });
    });
    
    return (<Row className="rich-text" style={{maxWidth: 1300, lineHeight:18, padding: '0 16px'}}>
        <Typography.Title className="page-header__title">{displayTitle}</Typography.Title>
        <Row><Typography.Paragraph>{parse(privacyPolicy) || ''}</Typography.Paragraph></Row>
    </Row>);
}

export default PrivacyPage;
import React from 'react';
import Slider from 'react-slick';
import './Testimonials.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const testimonialsData = [
    {
        message: "Enjoyed the course, will be registering for all future ones, cheers!",
        author: "W Tomlinson",
        occupation: "Electrician"
    },
    {
        message: "This is another testimonial message.",
        author: "J Smith",
        occupation: "Designer"
    },
    {
        message: "Yet another testimonial message.",
        author: "A Johnson",
        occupation: "Developer"
    }
];

const Testimonials: React.FC = (): JSX.Element => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <div className="row-padded-end testimonials">
            <Slider {...settings}>
                {testimonialsData.map((testimonial, index) => (
                    <blockquote key={index} className="testimonials__quote">
                        <div className="testimonials__quote-wrapper">
                            <p className="testimonials__quote-message">{testimonial.message}</p>
                            <footer className="testimonials__quote-author">{testimonial.author}<cite>, {testimonial.occupation}</cite></footer>
                        </div>
                    </blockquote>
                ))}
            </Slider>
        </div>
    );
}

export default Testimonials;

import { useState } from "react";
import { publicClientApplication } from "..";
import { AuthScopes } from "../AuthConfig";
import { getToken, isAdminUser } from "../MSALExtensions";
import axios from "axios";


function ApiTest() {
    const [infoState, setInfoState] = useState('');
    const [secureInfoState, setSecureInfoState] = useState('');

    if (isAdminUser(publicClientApplication)) {
        console.log("Application admin user!");
    }

    getToken(publicClientApplication, AuthScopes.EventApi).then((token) => {
        const acct = publicClientApplication.getAllAccounts();
        console.log('acct', acct);
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        console.log(headers.Authorization);

        axios.get('http://localhost:5271/Info', { headers })
            .then(
                (response) => {
                    console.log(response);
                    setInfoState(response.data);
                })
            .catch((error) => {
                console.log(error);
            });

        axios.get('http://localhost:5271/Info/admin', { headers })
            .then(
                (response) => {
                    console.log(response);
                    setSecureInfoState(response.data);
                })
            .catch((error) => {
                console.log(error);
            });
    });



    return (
        <div>
            <p>API Test</p>
            <p>
                {infoState}
            </p>
            <p>
                {secureInfoState}
            </p>
        </div>
    );
}

export default ApiTest;
import { useEffect, useState } from "react";
import axios from "axios";
import dayjs from 'dayjs';

import { EventListItem } from "../../../Utils/Types/EventTypes";

const useActiveEvents = () => {
    const eventQuery = `{
    event {
        contentItemId
        alias {
            alias
        }
        displayText
        isItListed
        displayImage {
            paths
            urls
        }
        eventVenue {
            displayName
            address1
            address2
            state
            city
            country
            postcode
        }
        startDate
        tags
    }
}`;

    const [data, setData] = useState<EventListItem[]>();
    const [isLoading, setIsLoading] = useState(false);
    const [err, setErr] = useState<any>();

    const fetchData = () => {
        setIsLoading(true);

        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_ORCHARD_API_URL}/api/graphql`,
            headers: {
                'Content-Type': 'application/graphql'
            },
            data: eventQuery
        };

        axios(config).then(function (response) {
            const results: EventListItem[] = response.data.data.event; // results array
            if (results) {
                const recentResults = results.filter(e => {
                    const d1 = dayjs();
                    const d2 = dayjs(e.startDate);
                    const dayDiff = d1.diff(d2, 'day');

                    return dayDiff >= 0 && dayDiff < 7;
                });
                setData(recentResults);
            }
        })
            .catch(function (error) {
                setErr(error);
            }).finally(() => {
                setIsLoading(false)
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    return { recentEvents: data, recentEventsLoading: isLoading, recentEventsErr: err };
};

export default useActiveEvents;
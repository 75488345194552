import { Row, Typography, Card, List } from 'antd';
import './UpcomingCourses.scss';
import { useEffect, useState } from 'react';
import axios from "axios";
import { UpcomingCourse, UpcomingCoursePerMonthYear } from '../../Utils/Types/CourseTypes';

const UPCOMING_COURSES_QUERY = `{
    upcomingCourse {
        contentItemId
        selectDate {
            date
        }
        displayText
    }
  }`;

const UpcomingCourses: React.FC = (): JSX.Element => {
    const [upcomingCourseList, setUpcomingCourseList] = useState([] as UpcomingCoursePerMonthYear[]);
    
    useEffect(() => {
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_ORCHARD_API_URL}/api/graphql`,
            headers: { 
              'Content-Type': 'application/graphql'
            },
            data : UPCOMING_COURSES_QUERY
        };
          
        axios(config).then(function (response: any) {
            const result = response.data.data.upcomingCourse;
            if (result) {
                const sorted: UpcomingCoursePerMonthYear[] = Object.values(result.reduce((r: any, c: any) => {
                    const [year, month, day] = c.selectDate.date.substr(0,10).split('-');
                    const key = `${year}-${month}`;
                    r[key] = r[key] || {key, courses: []};
                    r[key].courses.push(c.displayText);
                    return r;
                  },{}));
                setUpcomingCourseList(sorted)
            }
            else {
                // TODO: no data front end
            }
        })
        .catch(function (error: any) {
           console.log(error);
        });
    },[]);

    return (
        <Row className="row-padded upcoming-courses">
                <Typography.Title className="upcoming-courses-title">Upcoming CPD Courses</Typography.Title>
                <Typography.Link className="latest-courses__link" href="/courses">View all courses</Typography.Link>
                <List
                    className="upcoming-courses-list"
                    dataSource={upcomingCourseList}
                    split={false}
                    grid={{ gutter: 16, xs: 1,
                        sm: 1,
                        md: 2,
                        lg: 4,
                        xl: 4,
                        xxl: 4, }}
                    renderItem={(item) => (
                        <List.Item className="upcoming-courses-list-item">
                            <List.Item.Meta
                                title={(new Date(item.key)).toLocaleDateString('en-gb', {year: 'numeric', month: 'long',})}
                                description={(new Date(item.key)).toLocaleDateString('en-gb', {year: 'numeric', month: 'long',})}
                            />
                        </List.Item>
                    )}
                />
            </Row>
    );
};

export default UpcomingCourses;
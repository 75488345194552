import './EventDetailSection.scss';
import { Col, Row, Typography, Image } from "antd";
import { EventCustomHTMLSection, EventDetailSection, EventImageNew, EventSectionContent, EventTimeline } from "../../../Utils/Types/EventTypes";
import EventTimelineColumn from "./EventTimelineColumn/EventTimelineColumn";
import parse from 'html-react-parser';
import { getTextColor } from '../../../Utils/Helper/displayFunctions';
import { useEffect, useState } from 'react';
import axios from "axios";

interface EventVars {
    alias: string | undefined;
}

const EventDetailSectionView: React.FC<EventVars> = ({alias}): JSX.Element => {
    const [sections, setSections] = useState([] as EventDetailSection[]);

    const EVENT_DETAIL_SECTION_QUERY = `
    {
      event(where: {alias: {alias: "${ alias }"}}) {
        contentItemId
        displayText
        eventSections {
          contentItems {
            contentItemId
            ... on EventDetailSection {
              displayText
              description {
                html
              }
              contentItemId
              contentType
              sectionBackgroundColor
              bag {
                contentItems {
                  contentItemId
                  contentType
                  ... on EventTimeline {
                    contentItemId
                    displayText
                    contentType
                    bag {
                      contentItems {
                        ... on EventTimelinePoint {
                          contentItemId
                          contentType
                          event
                          time
                        }
                      }
                    }
                  }
                  ... on EventCustomHTMLSection {
                    contentItemId
                    contentType
                    htmlBody {
                      html
                    }
                  }
                  ... on EventImageNew {
                    linkedImage {
                      imageSrc {
                        paths
                        urls
                      }
                      link {
                        text
                        url
                      }
                    }
                    contentItemId
                    contentType
                  }
                }
              }
            }
          }
        }
      }
    }
    `;

    useEffect(() => {
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_ORCHARD_API_URL}/api/graphql`,
            headers: { 
              'Content-Type': 'application/graphql'
            },
            data : EVENT_DETAIL_SECTION_QUERY
        };
          
        axios(config).then(function (response: any) {
            const result = response.data.data.event;
            if (result) {
               if (result[0].eventSections?.contentItems) {
                setSections(result[0].eventSections.contentItems);
               }
            }
            else {
                // TODO: no data front end
            }
        })
        .catch(function (error: any) {
           console.log(error);
        });
    }, [  ]);

    const colParse = (col: EventSectionContent, colSpan: number, sectionBackgroundColor: string) => {
        let result = <></>
        
        switch(col.contentType) {
            case "EventCustomHTMLSection":
                const h = col as EventCustomHTMLSection;
                const fixedhtml = h.htmlBody.html.replace("<img src=\"", `<img src=\"${process.env.REACT_APP_ORCHARD_API_URL}`);
                result = <Col xs={24} sm={24} md={colSpan} className="event-custom-html-section">{parse(fixedhtml)}</Col>
                break;
            case "EventTimeline":
                const tl = col as EventTimeline;
                result = <Col xs={24} sm={24} md={colSpan}><EventTimelineColumn tl={tl} colorTheme={sectionBackgroundColor} sectionColor={sectionBackgroundColor}/></Col>
                break;
            case "EventImageNew":
                const i = col as EventImageNew;
                result = i.linkedImage.link.url ?
                    <Col xs={24} sm={24} md={colSpan}><a href={i.linkedImage.link.url} target="_blank"><img src={`${process.env.REACT_APP_ORCHARD_API_URL}${i.linkedImage.imageSrc.urls[0]}`} /></a></Col> : 
                    <Col xs={24} sm={24} md={colSpan}><Image src={`${process.env.REACT_APP_ORCHARD_API_URL}${i.linkedImage.imageSrc.urls[0]}`} /></Col>;
                break;
        }

        return result;
    }

    const generateSection = (section: EventDetailSection) => {
        const colSpan: number = Math.floor(24 / section.bag.contentItems.length);

        return <Row className="event-detail-section event-row" gutter={64} style={{
            backgroundColor: section.sectionBackgroundColor || 'white', color: getTextColor(section.sectionBackgroundColor)}}
        >
          {section.displayText && <Row className="event-detail-section--title"><Col span={24}><Typography.Title style={{ color: getTextColor(section.sectionBackgroundColor)  }}>{section.displayText}</Typography.Title></Col></Row>}
          {section.description?.html && <Row className="event-detail-section--desc"><Typography.Paragraph style={{ color: getTextColor(section.sectionBackgroundColor)  }}>{parse(section.description?.html)}</Typography.Paragraph></Row>}
          <Row className="event-detail-section--body" gutter={36} wrap>
            {section.bag.contentItems.map((col: EventSectionContent) => {
                return colParse(col, colSpan, section.sectionBackgroundColor)
            })}
          </Row>
        </Row>
    }

    return (<>
        { sections.length > 0 && <>{ sections.map((section: EventDetailSection) => { return generateSection(section) }) }</>}
    </>);
};

export default EventDetailSectionView;
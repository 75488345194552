import { useEffect, useState } from 'react';
import HomeCarousel from '../Components/Home/HomeCarousel/HomeCarousel';
import LatestCourses from '../Components/Home/LatestCourses/LatestCourses';
import UpcomingCourses from '../Components/UpcomingCourses/UpcomingCourses';
import axios from "axios";
import Testimonials from '../Components/Testimonials/Testimonials';

const COURSE_LIST_QUERY = `{
    course(status: PUBLISHED) {
      contentItemId
      contentType
      displayText
      alias {
        alias
      }
      displayImage {
        paths
        urls
      }
      carouselImage {
        paths
        urls
      }
      courseMetaDescription
      tags
      displayColor
    }
  }
  `;

const HOMEPAGE_COPIES_QUERY = `{
  pageCopies(first: 1) {
    latestCoursesCopy {
      html
      markdown
    }
  }
}`

const CAROUSEL_PROMOS_QUERY = `{
  homeCarouselPromotion(status: PUBLISHED) {
    contentType
    contentItemId
    callToActionLink {
      text
      url
    }
    colorTheme
    displayImage {
      urls
    }
    displayText
    bodyText {
      html
    }
  }
}`

function HomePage() {
    const [courseList, setCourseList] = useState([]);
    const [latestCoursesCopy, setLatestCoursesCopy] = useState("");
    const [carouselList, setCarouselList] = useState([]);

    useEffect(() => {

        const config = (query: string) => {return {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_ORCHARD_API_URL}/api/graphql`,
            headers: { 
              'Content-Type': 'application/graphql'
            },
            data : query
        }};
          
        axios(config(COURSE_LIST_QUERY)).then(function (response: any) {
            const result = response.data.data.course;
            if (result) {
               setCourseList(result);
            }
            else {
                // TODO: no data front end
            }
        })
        .catch(function (error: any) {
           console.log(error);
        });

        axios(config(HOMEPAGE_COPIES_QUERY)).then(function (response: any) {
          const result = response.data.data?.pageCopies[0]?.latestCoursesCopy?.html;
          if (result) {
             setLatestCoursesCopy(result);
          }
          else {
              // TODO: no data front end
          }
        })
        .catch(function (error: any) {
           console.log(error);
        });

        axios(config(CAROUSEL_PROMOS_QUERY)).then(function (response: any) {
          const result = response.data.data.homeCarouselPromotion;
          if (result) {
            console.log("carousel list", result);
             setCarouselList(result);
          }
          else {
              // TODO: no data front end
          }
        })
        .catch(function (error: any) {
           console.log(error);
        });
          
    }, []);

    return (
        <>
            <HomeCarousel courseList={courseList} carouselList={carouselList}/>
            <LatestCourses courseList={courseList} latestCoursesCopy={latestCoursesCopy}/>
            <UpcomingCourses />
            <Testimonials />
            {/* <HomeParallax /> */}
        </>
    );
}

export default HomePage;
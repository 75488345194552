import { UserOutlined } from '@ant-design/icons';
import './CourseLockedView.scss';
import { Button, Row, Typography, Space } from "antd";
import { useMsal } from '@azure/msal-react';
import { signInHandler } from '../../../Utils/Helper/msalFunctions';

const CourseLockedView: React.FC = (): JSX.Element => {
    const { instance } = useMsal();
    
    return (
        <Row className="locked-course">
            <Space className="locked-course__content" direction='vertical'>
                <div className="locked-course__description">
                <Typography.Title className="locked-course__title">Register an account or sign in to view this course.</Typography.Title>
                <Button className="locked-course__btn" onClick={() => signInHandler(instance)} block>Log in or register to unlock</Button>
                </div>
            </Space>
        </Row>
    );
};

export default CourseLockedView;
import './ContactView.scss'
import { Button, Col, Form, Input, Row, Typography, Alert, notification } from "antd";
import React, { createRef, useState } from 'react';
import email from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';

interface ContactUsState {
    name?: string;
    email?: string;
    phone?: string;
    message?: string;
}


const ContactView: React.FC = (): JSX.Element => {
    const [form] = Form.useForm();
    const [isCaptcha, setIsCaptcha] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    const recaptchaRef = createRef<ReCAPTCHA>();
    const recaptchaSiteKey = '6LcEDZMmAAAAAHsGe-r_TCOk-olTigF8B1-s6GBJ';

    const captchaPassed = (value: string | null) => {
        if (value)
        {
            setIsCaptcha(true);
        }
        else {
            setIsCaptcha(false);
        }
    }

    const onSubmit = async (values: ContactUsState) => {
        console.log(values);

        const serviceId = 'service_k3qf535';
        const templateId = 'template_f49sswt';
        const publicKey = '_Pe-C6wwiZO-kaT5W';


        const recaptchaToken = recaptchaRef.current?.getValue();
        const templateData = { ...values, 'g-recaptcha-response': recaptchaToken };

        console.log(templateData);

        await email.send(serviceId, templateId, templateData, publicKey).then((response: any) => {
            setShowError(false);
            setShowSuccess(true);
        }, (err: any) => {
            console.log('Email failed...', err);
            setShowError(true);
            setShowSuccess(false);
            /*api['error']({
                message: 'Error submitting your feedback',
                description:
                  'There is a problem submitting your feedback to our staff. Please try again later.',
              });*/
        });
    }

    return (<div className="contact">
        <Row className="row-padded-end contact-directory-row" gutter={64}>
            <Col xs={24} sm={24} md={12} className='contact-form'>
                <Form form={form} layout="vertical" onFinish={onSubmit}>
                    <Form.Item name="name" rules={[{ required: true, message: 'Please input your name!' }]}>
                        <Input className="contact-form__input" placeholder="Full name*" />
                    </Form.Item>
                    <Form.Item name="email" rules={[{
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      },
                      { required: true, message: 'Please input your email!' }]}
                    >
                        <Input className="contact-form__input" placeholder="Email*" />
                    </Form.Item>
                    <Form.Item name="tel">
                        <Input className="contact-form__input" placeholder="Phone*" />
                    </Form.Item>
                    <Form.Item name="message" rules={[{ required: true, message: 'Please input your message!' }]}>
                      <Input.TextArea className="contact-form__input--textarea" placeholder="Message*" showCount maxLength={250} />
                    </Form.Item>
                    <Form.Item>
                        <ReCAPTCHA
                            sitekey={recaptchaSiteKey}
                            ref={recaptchaRef}
                            size='compact'
                            onChange={captchaPassed} />
                    </Form.Item>
                    <Typography.Paragraph>* This field is required.</Typography.Paragraph>
                    <Form.Item>
                      <Button className="contact-form__submit" htmlType="submit" >
                        Submit
                      </Button>
                    </Form.Item>
                    {showSuccess && <Alert message="Message sent to our staff successfuly!" type="success" showIcon closable/>}
                    {showError && <Alert message="There is a problem submitting your feedback to our staff. Please try again later!" type="error" showIcon closable/>}
                </Form>
            </Col>
            <Col xs={24} sm={24} md={12} className="contact-location">
                 <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2418.9760696302314!2d-2.428831023365968!3d52.678469924484915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48707ff55627973f%3A0x8dae7b91a26a924b!2sLuceco%20Group!5e0!3m2!1sen!2suk!4v1719495738894!5m2!1sen!2suk" height="450" width="100%" frameBorder="0" loading="lazy"></iframe>
                <Typography.Title level={2} className="contact-location__title">Head Office</Typography.Title>
                <Typography.Paragraph>Luceco Distribution Centre, Stafford Park 1, Telford TF3 3BD United Kingdom</Typography.Paragraph>
            </Col>
        </Row>
    </div>);
};

export default ContactView;
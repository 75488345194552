import './AttendanceRecords.scss';
import { Row, Table, TableColumnsType } from "antd";
import { useEffect, useState } from "react";
import { publicClientApplication } from "../../../..";
import { AuthScopes } from "../../../../AuthConfig";
import { getToken } from "../../../../MSALExtensions";
import axios from "axios";
import { EventRecordProps } from '../EventRecords';
import Title from "antd/es/typography/Title";

interface AttendanceRecord {
    key: React.Key;
    username: string;
    firstName: string;
    lastName: string;
    attendedOn: Date;
}

const AttendanceRecords: React.FC<EventRecordProps> = ({selectedEvent}) => {
    const columns: TableColumnsType<AttendanceRecord> = [
        {
          title: 'Username',
          dataIndex: 'username',
          sorter: (a, b) => a.username.localeCompare(b.username),
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            sorter: (a, b) => a.firstName.localeCompare(b.firstName),
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            sorter: (a, b) => a.lastName.localeCompare(b.lastName),
        },
        {
            title: 'Attended On',
            dataIndex: 'attendedOn',
            defaultSortOrder: 'descend',
            sorter: (a, b) => new Date(a.attendedOn).valueOf() - new Date(b.attendedOn).valueOf(),
            render: (d:Date) => new Date(d).toLocaleDateString("en-GB", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' }),
        },
    ]

    const [attendanceList, setAttendanceList] = useState([]);

    const getAttendances = async (eventId: string) => {
        const accessToken = await getToken(publicClientApplication, AuthScopes.EventApi);
        const headers = {
            Authorization: `Bearer ${accessToken}`,
        };

        axios.get(`${process.env.REACT_APP_ACADEMY_EVENT_API}/Attendance/${eventId}`, { headers })
          .then((response) => {
              if (response.status === 200 && response.data) {
                setAttendanceList(response.data);
              }
          });
    }

    useEffect(() => {
       getAttendances(selectedEvent);
    }, [selectedEvent]);
    
    return (
        <>
            <Row>
            <Title level={3}>Event Attendance</Title>
            <Table
                columns={columns}
                dataSource={attendanceList}
                pagination={{ pageSize: 10 }}
              />
            </Row>
        </>
    );
};

export default AttendanceRecords;
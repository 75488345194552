import './YourCourses.scss';
import axios from "axios";
import { publicClientApplication } from '../../..';
import { AuthScopes } from '../../../AuthConfig';
import { getToken } from '../../../MSALExtensions';

import { Row, Typography, Card, Space, Button, List, Tag, Spin } from 'antd';
import { CourseListItem } from '../../../Utils/Types/CourseTypes';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getColorTag } from '../../../Utils/Helper/displayFunctions';
import { AuthenticatedTemplate } from '@azure/msal-react';
import CourseListLabel from '../../CourseList/CourseListLabel/CourseListLabel';



const YourCourses: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const [yourCoursesList, setYourCoursesList] = useState([] as CourseListItem[]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const accessToken = await getToken(publicClientApplication, AuthScopes.EventApi);
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };
        
        const response = await axios.get(`${process.env.REACT_APP_ACADEMY_COURSES_API}/Course/Progress`, { headers });

        if (response.status === 200 && response.data) {
          const cL = response.data;
          const config = (query: any) => ({
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_ORCHARD_API_URL}/api/graphql`,
            headers: { 
              'Content-Type': 'application/graphql'
            },
            data: query,
          });
   
          // Use Promise.all to ensure all queries are resolved
          const coursePromises = cL.map((i: any) => {
            const YOUR_COURSE_QUERY = `{
              course(where: {contentItemId: "${i.courseId}"}) {
                displayText
                contentItemId
                alias {
                  alias
                }
                displayImage {
                  paths
                  urls
                }
                carouselImage {
                  paths
                  urls
                }
                courseMetaDescription
                tags
                displayColor
              }
            }`;
     
            return axios(config(YOUR_COURSE_QUERY)).then((response) => {
              if (response.status === 200 && response.data.data) {
                const result = response.data.data.course[0];
                return result || null;
              }
              else {
                return null;
              }
            }).catch((error) => {
              console.error(error);
              return null; // Handle error by returning null or default value
            });
          });
   
          // Wait for all courses to be fetched
          const courses = await Promise.all(coursePromises);
          // Filter out null results
          const filteredCourses = courses.filter(course => course !== null);

          setYourCoursesList(filteredCourses);
          setIsLoading(false)

        } else {
          console.error("Failed to fetch course progress data");
          setIsLoading(false)
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
        setIsLoading(false)
      }
    })();
  }, []);

 


  return (<Row className="row-padded profile your-courses">
    <Row className="profile__bg" justify="center" align="middle">
      <Typography.Title className="your-courses-section-title" level={2}>Your Courses</Typography.Title>
      <Typography.Link className="your-courses__link" href="/courses">View all courses</Typography.Link>
      <Row gutter={16}>{isLoading ? <Spin /> :
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
            column: 3
          }}
          dataSource={yourCoursesList}
          className="your-courses-list"
          renderItem={(course: CourseListItem) => (
            <List.Item>
                <Card hoverable
                  bordered={false}
                  className="your-courses__image"
                  onClick={() => { navigate(`/courses/${course.alias?.alias}`) }}
                  cover={<>
                    <AuthenticatedTemplate>
                        <CourseListLabel courseId={course.contentItemId} />
                      </AuthenticatedTemplate>
                    <img alt="example" src={`${process.env.REACT_APP_ORCHARD_API_URL}${course.displayImage?.urls[0]}`} />
                  </>}
                >
                  <Space direction="vertical" size="large" className="course-item-content">
                  <Space size={[0, 4]} wrap>
                      {course.tags?.map((tag, index) =>
                        <Tag color={getColorTag(tag)} key={index}>{tag}</Tag>
                      )}
                    </Space>
                    <Card.Meta title={course.displayText} description={course.courseMetaDescription} />
                    <Button className="course-button" onClick={() => navigate(`/courses/${course.alias?.alias}`)}>Start</Button>
                    
                  </Space>
                </Card>
            </List.Item>
          )}
        />
        }</Row>
    </Row>
  </Row>
  );
};

export default YourCourses;

import {
    IPublicClientApplication,
    SilentRequest,
} from "@azure/msal-browser";
import { AuthScopes, B2CPolicies } from "./AuthConfig";

const ADMIN_CLAIM = 'ApplicationAdmin';

export async function getToken(instance: IPublicClientApplication, scope: AuthScopes): Promise<string | undefined> {
    const activeAccount = instance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = instance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        /*
        * User is not signed in. Throw error or wait for user to login.
        * Do not attempt to log a user in outside of the context of MsalProvider
        */
        console.log('Not signed in.');
        return '';
    }

    const accessTokenRequest: SilentRequest = {
        scopes: [scope],
        account: activeAccount || accounts[0],
        authority: B2CPolicies.Authorities.SignUpSignIn.Authority
    };

    const authResult = await instance.acquireTokenSilent(accessTokenRequest)
        .catch(async (err) => {
            return instance.acquireTokenRedirect(accessTokenRequest);
        });

    return authResult?.accessToken;
}


export function isAdminUser(instance: IPublicClientApplication): boolean {
    const activeAccount = instance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = instance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        /*
        * User is not signed in. Throw error or wait for user to login.
        * Do not attempt to log a user in outside of the context of MsalProvider
        */
        console.log('Not signed in.');
        return false;
    }

    const acct = activeAccount || accounts[0];
    return acct?.idTokenClaims?.hasOwnProperty(ADMIN_CLAIM) ?? false;
}
import { useParams } from 'react-router-dom';
import CourseView from '../Components/CourseView/CourseView';
import UpcomingCourses from '../Components/UpcomingCourses/UpcomingCourses';

function CoursePage() {
    const { alias } = useParams();

    return (
        <>
            <CourseView alias={alias}/>
        </>
    );
}

export default CoursePage;
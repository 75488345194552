import { useParams } from 'react-router-dom';
import EventView from '../Components/EventView/EventView';
import PageHeader from '../Components/PageHeader/PageHeader';

function EventPage() {
    const { alias } = useParams();

    return (
        <>
            <EventView alias={alias}/>
        </>
    );
}

export default EventPage;
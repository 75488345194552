import './FeedbackView.scss'
import { Button, Checkbox, Col, Form, Input, Rate, Row, Select, Typography, Alert, notification, Modal } from "antd";
import React, { useEffect, useState } from 'react';
import email from '@emailjs/browser';
import { useMsal } from '@azure/msal-react';

interface feedbackVars {
  isOpen: boolean; // state in parent - default to false
  openModal: any; // setIsOpen(true) in parent
  closeModal: any; // setIsOpen(false) in parent
}

const FeedbackView: React.FC<feedbackVars> = ({ isOpen, openModal, closeModal }): JSX.Element => {
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    const { accounts, instance } = useMsal();

    useEffect(() => {
      /* PREPOPULATE FROM ACCOUNT */
      (async() => {
        if (accounts.length > 0) {
  
          form.setFieldsValue({ name: accounts[0].idTokenClaims?.name, email: accounts[0].idTokenClaims?.email });
        }
      })();
    }, []);
     

    const onSubmit = async (values: any) => {
        console.log(values);

        /* TEST KEYS
        const serviceId = 'service_hht2szs';
        const templateId = 'template_4462sie';
        const publicKey = 'l5MFpu-i1-J7sLIoz';*/

        const serviceId = 'service_k3qf535';
        const templateId = 'template_j6ecowi';
        const publicKey = '_Pe-C6wwiZO-kaT5W';

        const templateData = values;

        console.log(templateData);

        await email.send(serviceId, templateId, templateData, publicKey).then((response: any) => {
            // show success
            closeModal();
        }, (err: any) => {
            console.log('Email failed...', err);
            api['error']({
                message: 'Error submitting your feedback',
                description:
                  'There is a problem submitting your feedback to our staff. Please try again later.',
              });
        });

        closeModal();
    }

    return (
      <Modal closable
        open={isOpen}
        onOk={onSubmit}
        onCancel={closeModal}
        footer={[]}
        className="feedback-modal"
      >
          <Row className="row-padded">
            <Typography.Title>We'd love to hear your feedback</Typography.Title>
            <Form form={form} layout="vertical" className="feedback-form" onFinish={onSubmit}>
                <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input your name!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="email" label="Email" rules={[{
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  { required: true, message: 'Please input your email!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item name="rate" label="How would you rate our current course content?">
                  <Rate allowHalf/>
                </Form.Item>
                <Form.Item name="improvements" label="How could we improve our training content?">
                  <Input.TextArea showCount maxLength={250} />
                </Form.Item>
                <Form.Item name="desired-content-types" label="What type of content would you like more of?" rules={[{ required: true, message: 'Please select the type of content you would like to see more of!' }]}>
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder="Please select"
                      options={[
                        { value: 'how-to guides', label: '\"How To\" Guides' },
                        { value: 'instructional video', label: 'Instructional Video' },
                        { value: 'installation guides', label: 'Installation Guides' },
                        { value: 'technical info', label: 'Technical Information'},
                        { value: 'reference guides', label: 'Reference Guides'},
                        { value: 'product info', label: 'Product Information'},
                      ]}
                    />
                </Form.Item>
                <Form.Item name="areas-of-interest" label="Areas of interest" rules={[{ required: true, message: 'Please select your areas of interest!' }]}>
                    <Checkbox.Group
                    >
                        <Row>
                          <Col span={16}>
                            <Checkbox value="Wiring">Wiring</Checkbox>
                          </Col>
                          <Col span={16}>
                            <Checkbox value="Installation">Installation</Checkbox>
                          </Col>
                          <Col span={16}>
                            <Checkbox value="Lighting">Lighting</Checkbox>
                          </Col>
                          <Col span={16}>
                            <Checkbox value="CPD">CPD Accredited Courses</Checkbox>
                          </Col>
                          <Col span={16}>
                            <Checkbox value="Design">Design</Checkbox>
                          </Col>
                          <Col span={16}>
                            <Checkbox value="Other">Other</Checkbox>
                          </Col>
                        </Row>
                    </Checkbox.Group>
                </Form.Item>
                <Form.Item>
                  <Button className="feedback-submit-btn" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
            </Form>
          </Row>
      </Modal>
    );
};

export default FeedbackView;
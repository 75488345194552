import { useEffect, useState } from 'react';
import axios from "axios";
import './EventGMapView.scss';

interface EventVars {
    eventId: string;
}


const EventGMapView: React.FC<EventVars> = ({eventId}): JSX.Element => {
    const GMAP_QUERY = `{
        event(where: {contentItemId: "${eventId}"}) {
            contentItemId
            googleMapsEmbed {
              html
            }
        }
      }`

    const [srcLink, setSrcLink] = useState("");

    useEffect(() => {
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_ORCHARD_API_URL}/api/graphql`,
            headers: { 
              'Content-Type': 'application/graphql'
            },
            data : GMAP_QUERY
        };
          
        axios(config).then(function (response: any) {
            const result = response.data.data.event[0];
            if (result && result.googleMapsEmbed) {
                const newEmbedLink: string = result.googleMapsEmbed.html.substring(("<iframe src=\"").length).split(" ")[0].replace(/.$/,'');;
                setSrcLink(newEmbedLink)
            }
        })
        .catch(function (error: any) {
           console.log(error);
        });
      }, [  ]);

    return (
        <>{(srcLink && srcLink !== "") &&
            <iframe src={srcLink} height="450" width="100%" loading="lazy"></iframe>
        }</>
    );
};

export default EventGMapView;
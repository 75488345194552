import { Space, Timeline, Typography } from 'antd';
import { EventTimeline, TimelinePoint } from '../../../../Utils/Types/EventTypes';
import './EventTimelineColumn.scss';
import { getTextColor } from '../../../../Utils/Helper/displayFunctions';

interface EventVars {
    tl: EventTimeline;
    colorTheme: string;
    sectionColor: string;
}

const EventTimelineColumn: React.FC<EventVars> = ({tl, colorTheme, sectionColor}): JSX.Element => {
    
    const getTlItems = (i: TimelinePoint[]) => {
        const result = i.map((i: TimelinePoint) => {
            return { children: `${i.time} - ${i.event}`, color: colorTheme}
        })
        return result;
    }

    return (
        <Space direction="vertical">
            <Typography.Title style={{ color: getTextColor(sectionColor) }} level={3}>{tl.displayText}</Typography.Title>
            <Timeline style={{ color: getTextColor(sectionColor) }} items={
                getTlItems(tl.bag.contentItems)
            }/>
        </Space>
    );
};

export default EventTimelineColumn;
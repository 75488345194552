import './CourseList.scss';
import { Row, List, Card, Space, Button, Tag, Tabs, Spin } from 'antd';
import { CourseListItem } from '../../Utils/Types/CourseTypes';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { getColorTag } from '../../Utils/Helper/displayFunctions';

import { useMsal } from '@azure/msal-react';
import CourseListLabel from './CourseListLabel/CourseListLabel';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

const COURSE_LIST_QUERY = `{
  course(status: PUBLISHED) {
    displayText
    contentItemId
    alias {
      alias
    }
    displayImage {
      paths
      urls
    }
    courseMetaDescription
    tags
  }
}`;

const CourseList: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const { accounts } = useMsal();

  const [courseList, setCourseList] = useState<CourseListItem[]>([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { TabPane } = Tabs;

  useEffect(() => {
    setIsLoading(true);
    setIsLoggedIn(accounts.length > 0);

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_ORCHARD_API_URL}/api/graphql`,
      headers: {
        'Content-Type': 'application/graphql',
      },
      data: COURSE_LIST_QUERY,
    };

    axios(config)
      .then(function (response) {
        const result = response.data.data.course;
        if (result) {
          setCourseList(result.map((course: { tags: any[]; }) => ({
            ...course,
            tags: course.tags.map((tag: string) => tag.replace(/-/g, ' '))
          })));
          
        } else {
          // TODO: no data front end
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [accounts.length]);

  const uniqueTags = (courseList: CourseListItem[]): string[] => {
    return [...new Set(courseList.flatMap(item => item.tags))];
  };

  const [activeTag, setActiveTag] = useState<string>(uniqueTags(courseList)[0] || '');

  useEffect(() => {
    if (courseList.length > 0) {
      setActiveTag(uniqueTags(courseList)[0]);
    }
  }, [courseList]);

  const handleTabChange = (key: string) => {
    setActiveTag(key);
  };

  return (
    <div>
      <div className="course-list-tabs row-padded">
      <h3 className="course-list-tabs__title">Filter results</h3>
      <Tabs defaultActiveKey={uniqueTags(courseList)[0]} onChange={handleTabChange}>
        {uniqueTags(courseList).map(tag => (
          <TabPane tab={tag} key={tag}>
            <Row className="course-list-container row-padded">
              {isLoading ? (
                <div className="course-list-spin-container"><Spin /></div>
              ) : (
                <List
                  className="course-list"
                  grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 3,
                    xxl: 4,
                  }}
                  dataSource={courseList.filter(course => course.tags.includes(activeTag))}
                  renderItem={(course: CourseListItem) => (
                    <List.Item>
                      <Card
                        hoverable
                        bordered={false}
                        className={`latest-courses__image ${course.tags.join(' ')}`}
                        onClick={() => navigate(`/courses/${course.alias.alias}`)}
                        cover={
                          <>
                            <AuthenticatedTemplate>
                              <CourseListLabel courseId={course.contentItemId} />
                            </AuthenticatedTemplate>
                            <img
                              alt="example"
                              src={`${process.env.REACT_APP_ORCHARD_API_URL}${course.displayImage?.urls[0]}`}
                            />
                          </>
                        }
                      >
                        <Space direction="vertical" size="large" className="course-item-content">
                          <Space size={[0, 4]} wrap>
                            {course.tags.map(tag => (
                              <Tag color={getColorTag(tag)} className={tag} key={tag}>
                                {tag}
                              </Tag>
                            ))}
                          </Space>
                          <Card.Meta title={course.displayText} description={course.courseMetaDescription} />
                          <UnauthenticatedTemplate>
                            <Button className="course-button locked" onClick={() => navigate(`/courses/${course.alias.alias}`)}>
                              Unlock this course
                            </Button>
                          </UnauthenticatedTemplate>
                          <AuthenticatedTemplate>
                            <Button className="course-button unlocked" onClick={() => navigate(`/courses/${course.alias.alias}`)}>
                              Go to course
                            </Button>
                          </AuthenticatedTemplate>
                        </Space>
                      </Card>
                    </List.Item>
                  )}
                />
              )}
            </Row>
          </TabPane>
        ))}
      </Tabs>
      </div>
    </div>
  );
};

export default CourseList;

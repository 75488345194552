import { List, Row, Space, Tag, Tabs, Button, Card } from 'antd';
import './ResourcesList.scss'
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { Resource } from '../../Utils/Types/ResourceTypes';
import { getColorTag } from '../../Utils/Helper/displayFunctions';
import { Link, useNavigate } from 'react-router-dom';

const RESOURECS_QUERY = `{
    resource {
        contentItemId
        displayText
        displayImage {
          urls
        }
        description
        resourceFile {
          urls
        }
        tags
    }
  }
  `;

const ResourcesList: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const [resourcesList, setResourcesList] = useState<Resource[]>([]);

  const { TabPane } = Tabs;

  useEffect(() => {
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_ORCHARD_API_URL}/api/graphql`,
      headers: {
        'Content-Type': 'application/graphql'
      },
      data: RESOURECS_QUERY
    };

    axios(config).then(function (response) {
      const result = response.data.data.resource;
      if (result) {
        setResourcesList(result.map((resource: { tags: any[]; }) => ({
          ...resource,
          tags: resource.tags.map((tag: string) => tag.replace(/-/g, ' '))
        })));
      }
      else {
        // TODO: no data front end
      }
    })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const uniqueTags = (resourcesList: Resource[]): string[] => {
    return [...new Set(resourcesList.flatMap(item => item.tags))];
  };

  const [activeTag, setActiveTag] = useState<string>(uniqueTags(resourcesList)[0] || '');


  useEffect(() => {
    if (resourcesList.length > 0) {
      setActiveTag(uniqueTags(resourcesList)[0]);
    }
  }, [resourcesList]);

  const handleTabChange = (key: string) => {
    setActiveTag(key);
  };

  return (
    <div>
      <div className="course-list-tabs row-padded">
        <h3 className="course-list-tabs__title">Filter results</h3>
        <Tabs defaultActiveKey={uniqueTags(resourcesList)[0]} onChange={handleTabChange}>
          {uniqueTags(resourcesList).map(tag => (
            <TabPane tab={tag} key={tag}>
              <Row className="resources row-padded">
                <List
                  className="resource-item-content"
                  pagination={{
                    pageSize: 10
                  }}
                  grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 2,
                    md: 2,
                    lg: 3,
                    xl: 3,
                    xxl: 3,
                  }}
                  dataSource={resourcesList.filter(resource => resource.tags.includes(activeTag))}

                  renderItem={(resource: Resource) => (
                    <>
                      <Link to={`${process.env.REACT_APP_ORCHARD_API_URL}${resource.resourceFile.urls[0]}`} target='_blank'>
                        <List.Item>
                          <Card hoverable
                            bordered={false}
                            className={`resources__card ${resource.tags.join(' ')}`}
                            cover={<img className="resources__image" alt="example" src={`${process.env.REACT_APP_ORCHARD_API_URL}${resource.displayImage?.urls[0]}`} />}
                          >
                            <Space direction="vertical" size="large">
                              <Space size={[0, 4]} wrap>
                                {resource.tags?.map(tag =>
                                  <Tag className="resources__tag" color={getColorTag(tag)}>{tag}</Tag>
                                )}
                              </Space>
                              <Card.Meta className="resources__title" title={resource.displayText} />
                              <Button className="resources__btn" href={`${process.env.REACT_APP_ORCHARD_API_URL}${resource.resourceFile.urls[0]}`} target='_blank'>Read</Button>

                            </Space>
                          </Card>
                        </List.Item>
                      </Link>
                    </>
                  )}
                />
              </Row>
            </TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default ResourcesList;
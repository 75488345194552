import AboutView from "../Components/AboutView/AboutView";
import PageHeader from "../Components/PageHeader/PageHeader";


function AboutPage() {
    return (
        <>
            <PageHeader title="About" bannerUrl="./images/test-images/zoomi.jpg" desc=""/>
            <AboutView/>
        </>
    );
}


export default AboutPage;
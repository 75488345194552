import './AboutView.scss';
import { useEffect, useState, ReactNode } from "react";
import axios from "axios";
import { AboutDetailSection, AboutDetails } from '../../Utils/Types/CopyTypes';
import AboutDetailSectionView from './AboutDetailSectionView/AboutDetailSectionView';

interface Props {
  children?: ReactNode,
  className?: string,
  href?: string,
  style: any,
  // any props that come into the component
}

const AboutView: React.FC = (): JSX.Element => {
    const [aboutDetails, setAboutDetails] = useState({} as AboutDetails);

    const ABOUT_QUERY = `{
      aboutDetails {
        contentItemId
        contentType
        displayText
        bag {
          contentItems {
            ... on AboutDetailSection {
              displayText
              sectionBackgroundColor
              bag {
                contentItems {
                  contentType
                  ... on AboutCustomHTMLSection {
                    displayText
                    htmlBody {
                      html
                    }
                    contentItemId
                    contentType
                  }
                  ... on AboutImageNew {
                    linkedImage {
                      imageSrc {
                        paths
                        urls
                      }
                      link {
                        text
                        url
                      }
                    }
                    contentType
                    contentItemId
                  }
                }
              }
            }
          }
        }
      }
    }`;

    useEffect(() => {
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_ORCHARD_API_URL}/api/graphql`,
            headers: { 
              'Content-Type': 'application/graphql'
            },
            data : ABOUT_QUERY
        };
          
        axios(config).then(function (response: any) {
            const result = response.data.data.aboutDetails;
            if (result) {
                console.log(result)
                setAboutDetails(result[0])
            }
            else {
                // TODO: no data front end
            }
        })
        .catch(function (error: any) {
           console.log(error);
        });
    }, [  ]);

    return (<>
        {aboutDetails.contentItemId ?
        (
          <>
        <div className="about-content">
          {
            aboutDetails.bag?.contentItems?.length > 0 && <>
            {
              aboutDetails.bag?.contentItems.map((section: AboutDetailSection) => {
                return (
                  <AboutDetailSectionView section={section} colorTheme={section.sectionBackgroundColor}/>
                )
              })
            }</>
          }
        </div>
        </>
        ) : ''
}
</>
)
}


export default AboutView;
import ContactView from "../Components/ContactView/ContactView";
import PageHeader from "../Components/PageHeader/PageHeader";
import { useEffect, useState } from "react";
import axios from "axios";

function ContactPage() {
    const CONTACT_COPY_QUERY = `{
        pageCopies(first: 1) {
            contactPageCopy {
                html
                markdown
              }
        }
      }`

      const [contactPageCopy, setContactPageCopy] = useState("");

    useEffect(() => {
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_ORCHARD_API_URL}/api/graphql`,
            headers: { 
              'Content-Type': 'application/graphql'
            },
            data : CONTACT_COPY_QUERY
        };
          
        axios(config).then(function (response: any) {
            const result = response.data.data?.pageCopies[0]?.contactPageCopy?.html;
          if (result) {
            setContactPageCopy(result);
          }
          else {
              // TODO: no data front end
          }
        });
    });
    
    return (<>
        <PageHeader title="Get in touch with us" bannerUrl="./images/test-images/joinluceco.jpg" desc={contactPageCopy}/>
        <ContactView />
    </>
    );
}

export default ContactPage;
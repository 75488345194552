import './HomeCarousel.scss';
import { Carousel, Button, Space } from 'antd';
import { CourseSliderItem } from '../../../Utils/Types/CourseTypes';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';

interface HomeCarouselVars {
    courseList: any;
    carouselList: any;
}

const HomeCarousel: React.FC<HomeCarouselVars> = ({courseList, carouselList}): JSX.Element => {
    const navigate = useNavigate();
    const [slideList, setSlideList] = useState([] as any);

    useEffect(() => {
        if (courseList) {
            const featured = courseList.filter((r: CourseSliderItem) => 
                r.tags?.includes('featured')
            )
            const l: any = carouselList.concat(featured);
            setSlideList(l);
        }
        else {
            // TODO: no data front end
        }
          
    }, [courseList]);

    return (
        <Carousel className='carousel' autoplay>
            {slideList.map((slide: any, index: number) => (
                <div key={index}>
                 <div 
                 className="carousel-area"
                 style={
                    {
                        color: '#fff',
                        backgroundImage: `url('${process.env.REACT_APP_ORCHARD_API_URL}${slide.contentType === "HomeCarouselPromotion" ? slide.displayImage?.urls[0] : (slide.carouselImage?.urls[0] ?? slide.displayImage?.urls[0])}')`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '550',
                      }
                 }>
                    <div className="carousel-section">
                        <Space direction="vertical" className="carousel-content">
                            <div className="carousel-section-header">{slide.displayText}</div>
                            <span>{slide.contentType === "HomeCarouselPromotion" ? parse(slide.bodyText.html) : slide.courseMetaDescription}</span>
                            <Button ghost block className="carousel-section-btn" onClick={() => slide.contentType === "HomeCarouselPromotion" ? window.location.replace(slide.callToActionLink.url) : navigate(`/courses/${slide.alias.alias}`)}>{slide.contentType === "HomeCarouselPromotion" ? slide.callToActionLink.text : "View course"}</Button>
                        </Space>
                    </div>
                 </div>
                </div>
            ))}
        </Carousel>
    );
};

export default HomeCarousel;

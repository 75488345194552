import './AuthRedirectPages.scss';
import { Result, Typography } from 'antd';
import PageHeader from '../../Components/PageHeader/PageHeader';
import { useNavigate } from 'react-router-dom';

function LoginRedirectPage() {
    const navigate = useNavigate();

    return (
        <div className="auth-redirect-page">
            <PageHeader title="Login Successful" bannerUrl="./images/test-images/joinluceco.jpg" desc={null}/>
            <Result
                status="success"
                title="Welcome Back!"
                subTitle="You are ready to resume learning."
                extra={[
                  <Typography.Link className="auth-redirect__button" onClick={() => navigate(`/courses`)}>
                    View all courses
                  </Typography.Link>,
                  <Typography.Link className='auth-redirect__button' onClick={() => navigate(`/events`)}>See our upcoming events</Typography.Link>,
                ]}
              />
        </div>
    );
}

export default LoginRedirectPage;
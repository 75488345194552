import './AboutDetailSectionView.scss';
import { Col, Row, Typography, Image } from "antd";
import parse from 'html-react-parser';
import { getTextColor } from '../../../Utils/Helper/displayFunctions';
import { AboutCustomHTMLSection, AboutDetailSection, AboutImageNew, AboutSectionContent } from '../../../Utils/Types/CopyTypes';

interface AboutVars {
    section: AboutDetailSection;
    colorTheme: string;
}

const AboutDetailSectionView: React.FC<AboutVars> = ({section, colorTheme}): JSX.Element => {

    const colSpan: number = Math.floor(24 / section.bag.contentItems.length);

    const colParse = (col: AboutSectionContent) => {
        let result = <></>
        
        switch(col.contentType) {
            case "AboutCustomHTMLSection":
                const h = col as AboutCustomHTMLSection;
                const fixedhtml = h.htmlBody.html.replace("<img src=\"", `<img src=\"${process.env.REACT_APP_ORCHARD_API_URL}`);
                result = <Col xs={24} sm={24} md={colSpan} className="about-custom-html-section">{parse(fixedhtml)}</Col>
                break;
            case "AboutImageNew":
                const i = col as AboutImageNew;
                result = i.linkedImage.link.url ?
                    <Col xs={24} sm={24} md={colSpan}><a href={i.linkedImage.link.url} target="_blank"><img src={`${process.env.REACT_APP_ORCHARD_API_URL}${i.linkedImage.imageSrc.urls[0]}`} /></a></Col> : 
                    <Col xs={24} sm={24} md={colSpan}><Image src={`${process.env.REACT_APP_ORCHARD_API_URL}${i.linkedImage.imageSrc.urls[0]}`} /></Col>;
                break;
        }

        return result;
    }

    return (
        <Row className="about-detail-section about-row" gutter={64} style={{
            backgroundColor: section.sectionBackgroundColor || 'white', color: getTextColor(section.sectionBackgroundColor)}}
        >
          {section.displayText && <Row className="about-detail-section--title"><Col span={24}><Typography.Title style={{ color: getTextColor(section.sectionBackgroundColor)  }}>{section.displayText}</Typography.Title></Col></Row>}
          {section.description?.html && <Row className="about-detail-section--desc"><Typography.Paragraph style={{ color: getTextColor(section.sectionBackgroundColor)  }}>{parse(section.description?.html)}</Typography.Paragraph></Row>}
          <Row className="about-detail-section--body" gutter={36} wrap>
            {section.bag.contentItems.map((col: AboutSectionContent) => {
                return colParse(col)
            })}
          </Row>
        </Row>
    );
};

export default AboutDetailSectionView;
import './LatestCourses.scss';
import { Row, Typography, Card, Space, Button, List, Tag, Col } from 'antd';
import { CourseListItem } from '../../../Utils/Types/CourseTypes';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getColorTag } from '../../../Utils/Helper/displayFunctions';
import parse from 'html-react-parser';
import HomeAbout from '../HomeAbout/HomeAbout';

interface LatestCourseVars {
  courseList: any;
  latestCoursesCopy: string;
}

const LatestCourses: React.FC<LatestCourseVars> = ({ courseList, latestCoursesCopy }): JSX.Element => {
  const navigate = useNavigate();

  //const latestCourseList: CourseListItem[] = testOrchard.data.course;
  const [latestCourseList, setLatestCourseList] = useState([]);

  useEffect(() => {
    if (courseList) {
      setLatestCourseList(courseList?.slice(0, 3))
    }
    else {
      // TODO: no data front end
    }
  }, [courseList]);


  return (<Row className="row-padded latest-courses">
    <Row className="latest-courses__bg">
    <Col xs={24} lg={14}>
      <Typography.Title className="latest-courses-section-title" level={2}>Latest Courses</Typography.Title>
      <Typography.Link className="latest-courses__link" href="/courses">View all courses</Typography.Link>

      <Typography.Paragraph className="latest-courses__description">
        {parse(latestCoursesCopy) || ""}
      </Typography.Paragraph>
      </Col>
      <Col xs={24} lg={6}>
        <HomeAbout />
      </Col>
      <Col xs={24}>
      <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
            column: 3
          }}
          dataSource={latestCourseList}
          className="latest-courses-list"
          renderItem={(course: CourseListItem) => (
            <List.Item>
                <Card hoverable
                  bordered={false}
                  className="latest-courses__image"
                  onClick={() => { navigate(`/courses/${course.alias.alias}`) }}
                  cover={<img alt="example" src={`${process.env.REACT_APP_ORCHARD_API_URL}${course.displayImage?.urls[0]}`} />}
                >
                  <Space direction="vertical" size="large" className="course-item-content">
                  <Space size={[0, 4]} wrap>
                      {course.tags?.map((tag, index) =>
                        <Tag color={getColorTag(tag)} key={index}>{tag}</Tag>
                      )}
                    </Space>
                    <Card.Meta title={course.displayText} description={course.courseMetaDescription} />
                    <Button className="course-button" onClick={() => navigate(`/courses/${course.alias.alias}`)}>Start</Button>
                    
                  </Space>
                </Card>
            </List.Item>
          )}
        />
      </Col>
    </Row>
    <Row gutter={16}>
       

      </Row>
  </Row>
  );
};

export default LatestCourses;

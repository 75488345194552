import { Row, Select } from "antd";
import AttendanceRecords from "./AttendanceRecords/AttendanceRecords";
import { useEffect, useState } from "react";
import axios from "axios";
import RegistrationRecords from "./RegistrationRecords/RegistrationRecords";

export interface EventRecordProps {
    selectedEvent: string;
}

const EVENT_LIST_QUERY = `{
    event {
        contentItemId
        displayText
    }
}`;

const EventRecords = () => {
    const [eventList, setEventList] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState("");

    const selectEvent = (value: string) => {
       setSelectedEvent(value);
    };
    
    useEffect(() => {
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_ORCHARD_API_URL}/api/graphql`,
            headers: {
                'Content-Type': 'application/graphql'
            },
            data: EVENT_LIST_QUERY
        };

        axios(config).then(function (response) {
            const result = response.data.data.event;
            if (result) {
                setEventList(result);
            }
            else {
                // TODO: no data front end
            }
        })
            .catch(function (error) {
                console.log(error);
            });

    }, []);

    return <>
        <Row>
            <Select placeholder='Select Event' onChange={selectEvent} allowClear>
                {eventList.map((e: any) => (
                    <Select.Option value={e.contentItemId}>{e.displayText}</Select.Option>
                ))};
            </Select>
        </Row>
        <AttendanceRecords selectedEvent={selectedEvent}/>
        <RegistrationRecords selectedEvent={selectedEvent}/>
    </>;
}

export default EventRecords;
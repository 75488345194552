export enum AuthScopes {
    Orchard = 'https://lucecoacademy.onmicrosoft.com/aed223f0-55aa-463a-a249-ce78c28a82f4/Default',
    QuizApi = 'https://lucecoacademy.onmicrosoft.com/a061bae1-f27a-48b3-a588-fcc231cb1c93/Default',
    EventApi = 'https://lucecoacademy.onmicrosoft.com/08a6e440-a991-4eca-9587-b9d137467dca/Default',
    CourseApi = 'https://lucecoacademy.onmicrosoft.com/08a6e440-a991-4eca-9587-b9d137467dca/Default',
    CertsApi = 'https://lucecoacademy.onmicrosoft.com/e25e3f31-8a7a-4b88-a097-beb28f0dee1d/Default'
};

export const B2CPolicies = {
    Names: {
        SignUpSignIn: 'B2C_1A_SIGNUP_SIGNIN'
    },
    Authorities: {
        SignUpSignIn: {
            Authority: 'https://lucecoacademy.b2clogin.com/lucecoacademy.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN',
        }
    },
    AuthorityDomain: 'lucecoacademy.b2clogin.com',
};

export const MSALConfig = {
    auth: {
        clientId: '755e9af2-7a7b-4cd5-9560-c20408ae5f40',
        authority: B2CPolicies.Authorities.SignUpSignIn.Authority,
        knownAuthorities: [B2CPolicies.AuthorityDomain],
        redirectUri: '/',
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
    }
};
import './ErrorPage.scss';
import { ConfigProvider, Row, Button, Layout, Typography } from 'antd';
import { useRouteError, useNavigate } from "react-router-dom";
import Navbar from '../../Components/Navbar/Navbar';
import LucecoFooter from '../../Components/LucecoFooter/LucecoFooter';

function ErrorPage() {
  const navigate = useNavigate();
  const error = useRouteError();

  return (
      <ConfigProvider theme={{
            token: {
              fontFamily: 'Jost',
            },
          }}
      >
        <Layout className="ErrorPage">
          <Navbar />
          <Row className="error-body">
            <Typography.Title>Oops! We cannot find that page!</Typography.Title>
            <Button className="back-btn" onClick={() => navigate(-1)}>Go Back</Button>
          </Row>
          <LucecoFooter />
       </Layout>
      </ConfigProvider>
  );
}

export default ErrorPage;
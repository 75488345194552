
import './CourseListLabel.scss';
import { useState, useEffect } from "react";
import axios from "axios";
import { publicClientApplication } from "../../..";
import { AuthScopes } from "../../../AuthConfig";
import { getToken } from "../../../MSALExtensions";
import { Tag } from "antd";

interface courseListVars {
    courseId: string;
}

const CourseListLabel: React.FC<courseListVars> = ({ courseId }): JSX.Element => {
    const [label, setLabel] = useState<string | null>(null);
    const [labelColor, setLabelColor] = useState('success');
    
    useEffect(() => {
        (async() => {
            const accessToken = await getToken(publicClientApplication, AuthScopes.EventApi);
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            axios.get(`${process.env.REACT_APP_ACADEMY_COURSES_API}/Course/Progress/${courseId}`, { headers })
              .then((response) => {
                  if (response.status === 200 && response.data) {
                    if (response.data.currentStepId === '' || !response.data.currentStepId)
                    {
                        setLabel(null);
                    }
                    else if (response.data.currentStepId === 'Completed') {
                        setLabel('Completed');
                        setLabelColor('success');
                    }
                    else {
                        setLabel('In Progress');
                        setLabelColor('warning');
                    }
                  }
                  else {
                    // no tag
                  }
              })
              .catch((error) => {
                console.log(error);
              });
        })();
    }, [])

    return (
        (label ? (<Tag className="course-progres-label" color={labelColor} style={{ position: 'absolute', width: 'fit-content', margin: '12px'}}>
            {label}
        </Tag>) : <></> )
    );
};

export default CourseListLabel;